<script setup lang="ts">
import ApiService from "@/services/api";
import { $t } from "@/i18n";
import {
  MaterialSubType,
  MeasurementUnit,
  ProcessDraftStatus,
  ProductTemplateDetailsDto,
  ResourceInfoDto,
  ResourcePurpose,
  ResourceSubType,
  ResourceType
} from "@masta/generated-model";
import { computed, ref, watch } from "vue";
import ResourcePicker from "@/components/Resources/ResourcePicker.vue";
import { replaceResource, ProcessDraft } from "@/components/ProcessDrafts/ProcessDraftModels";
import { createEmptyProcessDraft, createMaterialInfo, createDraftFromTemplate, getProcessNameFromResource } from "@/components/ProcessDrafts/model-from-product-template";
import { getEnumTitleValuePairs } from "@/composables/enumHelpers";
import { translateMeasurementUnit, translateResourcePurpose, translateResourceSubType } from "@/composables/translateEnum";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { VForm } from "vuetify/components";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { hasProductionPurpose } from "@/components/ProcessDrafts/validation";
import { useScenariosStore } from "@/store/ScenariosStore";

const modelValue = defineModel<boolean>({ required: true });

const emit = defineEmits<{
  (e: "dialog-result", result: ProcessDraft | null): void;
}>();

enum CreateProcessDraftOptions {
  Manually,
  FromAi
}

interface AddProductOption {
  title: string;
  value: CreateProcessDraftOptions;
}

const outcomeResource = ref<ResourceInfoDto | null>(null);
const sourceResource = ref<ResourceInfoDto | null>(null);
const options = ref<AddProductOption[]>([
  {
    title: $t("processDraft-addProcessDraftDialog-manually-label", { $: "Create manually" }),
    value: CreateProcessDraftOptions.Manually
  },
  {
    title: $t("processDraft-addProcessDraftDialog-fromAi-label", { $: "Create from AI" }),
    value: CreateProcessDraftOptions.FromAi
  }
]);
const selectedOption = ref<AddProductOption>(options.value[0]);
const resourceName = ref<string>();
const processLoadProblem = ref(false);
const selectedUnit = ref<MeasurementUnit>(MeasurementUnit.Piece);
const form = ref<InstanceType<typeof VForm> | null>(null);
const valid = ref<boolean>(false);
const showProgress = ref(false);
const snackbarsStore = useSnackbarsStore();
const scenarioStore = useScenariosStore();

watch(
  () => modelValue.value,
  (newValue) => {
    if (newValue) {
      clearValues();
    }
  }
);
watch(
  () => selectedOption.value,
  () => form.value?.resetValidation()
);

watch(
  () => sourceResource.value,
  () => (processLoadProblem.value = false)
);

const showManual = computed(() => selectedOption.value.value === CreateProcessDraftOptions.Manually);
const outcomeResourceRules = computed(() => (showManual.value ? [requiredRule] : []));
const sourceResourceRules = computed(() => (showManual.value ? [processLoadProblemRule] : [requiredRule]));

function onCancel() {
  close();
  emit("dialog-result", null);
}

function clearValues() {
  showProgress.value = false;
  valid.value = false;
  outcomeResource.value = null;
  sourceResource.value = null;
  selectedOption.value = options.value[0];
  resourceName.value = undefined;
  selectedUnit.value = MeasurementUnit.Piece;
  processLoadProblem.value = false;
}

async function onAdd() {
  const result = await form.value?.validate();
  if (!result || !result.valid) {
    return;
  }
  showProgress.value = true;
  switch (selectedOption.value.value) {
    case CreateProcessDraftOptions.Manually:
      await createManually();
      break;
    case CreateProcessDraftOptions.FromAi:
      await createFromAi();
      break;
  }
  showProgress.value = false;
}

async function createFromAi() {
  if (sourceResource.value) {
    const process = createEmptyProcessDraft(sourceResource.value);
    process.status = ProcessDraftStatus.WaitingForAi;
    closeWithProcess(process);
  }
}

function closeWithProcess(process: ProcessDraft) {
  close();
  emit("dialog-result", process);
}

function close() {
  modelValue.value = false;
}

async function createManually() {
  let process: ProcessDraft | null = null;
  if (!outcomeResource.value) {
    return;
  }
  if (sourceResource.value) {
    process = await createProcess(sourceResource.value);
    if (!process) {
      snackbarsStore.createSnackbar({
        message: $t("processDraft-addProcessDraftDialog-cannotFindValidProcessForMaterialError-message", { $: "Could not find valid process for source material." }),
        type: "error",
        closeable: true,
        position: "top-right"
      });
      processLoadProblem.value = true;
      form.value?.validate();
      return;
    }
    replaceResource(process, sourceResource.value, outcomeResource.value);
    process.name = getProcessNameFromResource(outcomeResource.value);
  } else {
    console.log("No source resource selected");
    process = await createProcess(outcomeResource.value);
    console.log("Process created", process);
  }

  if (!process) {
    process = createEmptyProcessDraft(outcomeResource.value);
  }

  closeWithProcess(process);
}

async function createProcess(res: ResourceInfoDto) {
  try {
    const templates = await getTemplates(res);
    if (templates.length > 0) {
      return createProcessFromTemplates(templates, res);
    }
  } catch (e) {
    console.error(e);
  }

  return null;
}

async function getTemplates(res: ResourceInfoDto) {
  const response = await ApiService.productTemplates.getForResource(res.id, res.scenarioId);
  if (response.data && response.data.templates) {
    return response.data.templates;
  }

  return [];
}

function createProcessFromTemplates(productTemplates: ProductTemplateDetailsDto[], res: ResourceInfoDto): ProcessDraft {
  const rootTemplates = productTemplates.filter((x) => x.id === x.rootTaskId);
  const tasks = rootTemplates.filter((x) => x.id === x.rootTaskId).map((x) => createDraftFromTemplate(x, productTemplates, true));
  const task = tasks.find((x) => x.resource?.id === res.id);
  if (!task || !task.resource) {
    throw new Error("Template is not referencing selected resource");
  }
  return { name: getProcessNameFromResource(task.resource), status: ProcessDraftStatus.WorkInProgress, productTemplates: tasks, targetResource: task.resource };
}

function processLoadProblemRule(v: any): boolean | string {
  return !processLoadProblem.value || $t("processDrafts-edit-processLoadProblem-message", { $: "Cannot load valid process" });
}
</script>

<template>
  <v-dialog v-model="modelValue" :persistent="true" content-class="add-process-draft-dialog">
    <v-form ref="form" v-model="valid" @submit.prevent="">
      <v-card class="pa-8">
        <v-card-title class="d-flex align-center justify-space-between">
          <div class="text-h4">{{ $t("processDrafts-addProcessDraftDialog-title-label", { $: "Build technology" }) }}</div>
          <v-icon @click="onCancel">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-chip-group v-model="selectedOption" mandatory class="d-flex justify-center">
              <v-chip
                v-for="option in options"
                :key="option.value"
                :value="option"
                :class="option.value === selectedOption?.value ? 'bg-primary' : 'bg-indigo-lighten-5 text-primary'"
                size="large"
              >
                {{ option.title }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row v-if="showManual">
            <v-col cols="12">
              <ResourcePicker
                v-model="outcomeResource"
                class="flex-1-1-100"
                :readonly="false"
                :label="$t('processDraft-addProcessDraftDialog-outcomeProduct-label', { $: 'Outcome Product' })"
                :resource-types="[ResourceType.Material]"
                :rules="outcomeResourceRules"
                :hide-details="'auto'"
                :resource-sub-types="[ResourceSubType.MaterialSemiFinishedOrFinalProduct]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ResourcePicker
                v-model="sourceResource"
                class="flex-1-1-100"
                :readonly="false"
                :label="$t('processDraft-addProcessDraftDialog-sourceProduct-label', { $: 'Source of Technology Template' })"
                :resource-types="[ResourceType.Material]"
                :rules="sourceResourceRules"
                :hide-details="'auto'"
                :resource-sub-types="[ResourceSubType.MaterialSemiFinishedOrFinalProduct]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn class="ma-4" variant="elevated" color="secondary" block @click="onCancel">
                {{ $t("processDrafts-addProcessDraftDialog-cancel-action", { $: "Cancel" }) }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn class="ma-4" variant="elevated" color="primary" block :loading="showProgress" @click="onAdd">
                {{ $t("processDrafts-addProcessDraftDialog-add-action", { $: "Add" }) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<style lang="scss">
.add-process-draft-dialog {
  width: 30vw !important;
}

@media screen and (max-width: 1900px) {
  .add-process-draft-dialog {
    width: 50vw !important;
  }
}

@media screen and (max-width: 1450px) {
  .add-process-draft-dialog {
    width: 75vw !important;
  }
}
</style>
