import {
  CreateProcessDraftCommand,
  GetProcessDraftsPaginatedQuery,
  GetProductTemplatesPaginatedQuery,
  IAgGridPaginationResponse,
  ProcessDraftDto,
  UpdateProcessDraftCommand
} from "@masta/generated-model";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { IServerSideGetRowsParams } from "ag-grid-community";
import ApiService from "@/services/api";
import { mapProductTemplateDrafts } from "@/components/ProcessDrafts/mapping-dtos";
import { Store } from "pinia";
import { State as ScenarioState, useScenariosStore } from "@/store/ScenariosStore";

type CreateProcessDraftCommandType = CreateProcessDraftCommand;
type UpdateProcessDraftCommandType = UpdateProcessDraftCommand;

export class ProcessDraftsServerSideDataSource extends ServerSideDataSource<
  ProcessDraftDto,
  ProcessDraftDto,
  CreateProcessDraftCommandType,
  UpdateProcessDraftCommandType,
  string
> {
  private _filterByProductId: string | undefined;
  private _scenarioStore: Store<string, ScenarioState>;

  constructor(id: string, productId?: string) {
    super(id);
    this._filterByProductId = productId;
    this._scenarioStore = useScenariosStore();
  }

  public useFilteringByProductId(productId?: string) {
    this._filterByProductId = productId;
  }

  async create(entity: ProcessDraftDto): Promise<string> {
    const request = this.transformForCreate(entity);
    const response = await ApiService.processDrafts.create(request);
    return response.data;
  }

  async update(entity: ProcessDraftDto): Promise<string> {
    const request = this.transformForUpdate(entity);
    await ApiService.processDrafts.update(request);
    return entity.id;
  }

  async getAll(params: IServerSideGetRowsParams): Promise<IAgGridPaginationResponse<ProcessDraftDto>> {
    const request = {
      ...this.constructServerRequest(params),
      scenarioId: this.selectedScenarioId(),
      targetResourceId: this._filterByProductId
    } as GetProcessDraftsPaginatedQuery;
    const { data } = await ApiService.processDrafts.getPaginated(request);
    return data;
  }

  async getSingle(id: string): Promise<ProcessDraftDto> {
    console.log("getSingle scenarioId", this.selectedScenarioId());
    const response = await ApiService.processDrafts.getById(id, this.selectedScenarioId());
    return response.data;
  }

  async remove(entity: ProcessDraftDto): Promise<void> {
    await ApiService.processDrafts.remove(entity.id, this.selectedScenarioId());
    return;
  }

  protected transformForCreate(entity: ProcessDraftDto): CreateProcessDraftCommandType {
    return {
      scenarioId: this.selectedScenarioId(),
      name: entity.name,
      productTemplates: mapProductTemplateDrafts(entity.productTemplates),
      createProductTemplates: false,
      targetResourceBusinessId: null,
      targetResourceId: entity.targetResource?.id ?? null,
      createFromAi: false
    };
  }

  protected transformForUpdate(entity: ProcessDraftDto): UpdateProcessDraftCommandType {
    return {
      id: entity.id,
      scenarioId: this.selectedScenarioId(),
      name: entity.name,
      productTemplates: mapProductTemplateDrafts(entity.productTemplates),
      createProductTemplates: false,
      targetResourceId: entity.targetResource?.id ?? null
    };
  }

  private selectedScenarioId() {
    return this._scenarioStore.selectedScenario?.id ?? "";
  }
}
