/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  CellContextMenuEvent,
  CellCtrl,
  RowCtrl,
  CellKeyDownEvent,
  Column,
  GridApi,
  GridReadyEvent,
  IRowNode,
  RowEditingStartedEvent,
  RowEditingStoppedEvent,
  RowNode,
  RowSelectedEvent,
  RowValueChangedEvent, StoreRefreshedEvent
} from "ag-grid-community";
import { tryOnBeforeUnmount } from "@vueuse/core";
import { ref, watch } from "vue";
import { ServerSideDataSource } from "@/components/Grid/ServerSideDataSource";
import { Store, storeToRefs } from "pinia";
import { GridState, GridStateActions } from "@/store/GridRegistryState";
import { useErrorsStore } from "@/store/ErrorsStore";
import { ActionsControl } from "@/components/Grid/CrudActions";
import { ICustomCellEditor } from "@/components/Grid/CellEditors/ICustomCellEditor";
import { IInvalidColumnScrollEvent } from "@/components/Grid/UseInvalidColumnScroll";
import { isDefined } from "@/components/Common/Types";
import { getSelectedNodes, getSelectedRows } from "@/components/Grid/UseGridSelection";

// Hack for preventing exiting the row
// @source https://stackoverflow.com/questions/61804606/ag-grid-stops-editing-cells-when-any-other-cell-on-the-grid-is-clicked-how-to-p
CellCtrl.prototype.onCellFocused = function(event) {
  // @ts-ignore
  if (!this.cellComp || this.beans.gos.get("suppressCellFocus")) {
    return;
  }
  // @ts-ignore
  const cellFocused = this.beans.focusService.isCellFocused(this.cellPosition);
  // @ts-ignore
  this.cellComp.addOrRemoveCssClass("ag-cell-focus", cellFocused);
  // see if we need to force browser focus - this can happen if focus is programmatically set
  if (cellFocused && event && event.forceBrowserFocus) {
    // @ts-ignore
    const focusEl = this.cellComp.getFocusableElement();
    focusEl.focus({ preventScroll: !!event.preventScrollOnBrowserFocus });
  }
};
// Hack for preventing exiting the row
// @source https://stackoverflow.com/questions/61804606/ag-grid-stops-editing-cells-when-any-other-cell-on-the-grid-is-clicked-how-to-p
// @ts-ignore
RowCtrl.prototype.onCellFocusChanged = function() {
  // @ts-ignore
  const rowFocused = this.beans.focusService.isRowFocused(this.rowNode.rowIndex, this.rowNode.rowPinned);
  // @ts-ignore
  if (rowFocused !== this.rowFocused) {
    // @ts-ignore
    this.rowFocused = rowFocused;
    // @ts-ignore
    this.setFocusedClasses();
  }
};

export const useCrudActions = (
  gridWrapperProps: {
    createBtn?: boolean;
    createBtnDisabled?: boolean;
    customCreateAction?: boolean;
    createDefaultValue?: any;
    duplicateBtn?: boolean;
    duplicateBtnDisabled?: boolean;
    customDuplicateAction?: boolean;
    deleteBtn?: boolean;
    deleteBtnDisabled?: boolean;
    editBtn?: boolean;
    editBtnDisabled?: boolean;
    customEditAction?: boolean;
    customDeleteAction?: boolean;
    refreshBtn?: boolean;
    refreshBtnDisabled?: boolean;
    serverSide?: boolean;
    serverSideDatasource?: ServerSideDataSource<any, any, any, any>;
    expandAfterCreate?: boolean;
    getParentIdPropertyName?: () => string;
  },
  $emits: {
    (event: "create-action"): void;
    (event: "edit-action"): void;
    (event: "duplicate-action"): void;
    (event: "delete-action"): void;
    (event: "refresh-action"): void;
    (event: "cancel-action"): void;
    (event: "save-action", data: any): void;
    (event: "single-row-refreshed", data: any): void;
  },
  gridStore: Store<string, GridState, any, GridStateActions>
) => {
  const errorsStore = useErrorsStore();
  const { isEditing, isUpdating } = storeToRefs(gridStore);
  let gridApi: GridApi = null!;

  function onGridReady(event: GridReadyEvent) {
    gridApi = event.api;
    gridApi.addEventListener("rowSelected", onRowSelected);
    gridApi.addEventListener("cellContextMenu", onCellContextMenu);
    gridApi.addEventListener("rowEditingStarted", onRowEditingStarted);
    gridApi.addEventListener("rowEditingStopped", onRowEditingStopped);
    gridApi.addEventListener("rowValueChanged", onRowValueChanged);
    gridApi.addEventListener("cellKeyDown", onCellKeyDown);
    gridApi.addEventListener("storeRefreshed", onStoreRefreshed);
    // gridApi.addEventListener("createChildForGroupAction", onCreateChildForGroupAction);
    actionsControl.value.editDisabled = getSelectedRows(gridApi).length !== 1;
    actionsControl.value.deleteDisabled = getSelectedRows(gridApi).length !== 1;
    actionsControl.value.duplicateDisabled = getSelectedRows(gridApi).length !== 1;
  }

  tryOnBeforeUnmount(() => {
    if (!gridApi) return;
    gridApi.removeEventListener("rowSelected", onRowSelected);
    gridApi.removeEventListener("cellContextMenu", onCellContextMenu);
    gridApi.removeEventListener("rowEditingStarted", onRowEditingStarted);
    gridApi.removeEventListener("rowEditingStopped", onRowEditingStopped);
    gridApi.removeEventListener("rowValueChanged", onRowValueChanged);
    gridApi.removeEventListener("cellKeyDown", onCellKeyDown);
    gridApi.removeEventListener("storeRefreshed", onStoreRefreshed);
    // gridApi.removeEventListener("createChildForGroupAction", onCreateChildForGroupAction);
  });

  function onCellKeyDown(e: CellKeyDownEvent) {
    if ((e?.event as KeyboardEvent)?.key === "Delete") {
      onDeleteAction(e.event as Event, null);
    }
  }

  const actionsControl = ref<ActionsControl>({
    createDisabled: false,
    refreshDisabled: false,
    editDisabled: false,
    duplicateDisabled: false,
    deleteDisabled: false,
    saveActionEnabled: false,
    cancelActionEnabled: false
  });
  const deleteDialog = ref(false);
  const rowValueChanged = ref(false);
  const previousRowData = ref(null);
  const cancelBtnClicked = ref(false);
  const contextRowNode = ref<IRowNode | null | undefined>(null);
  const lastEditedRowId = ref<string | null | undefined>(null);

  watch(isEditing, (v) => {
    actionsControl.value.cancelActionEnabled = v;
    actionsControl.value.saveActionEnabled = v;
    actionsControl.value.editDisabled = v;
    actionsControl.value.duplicateDisabled = v;
    actionsControl.value.deleteDisabled = v;
    actionsControl.value.createDisabled = v;
    actionsControl.value.refreshDisabled = v;
  });

  function onRowSelected(event: RowSelectedEvent) {
    // fire only when row is selected
    if (!event.node.isSelected()) {
      return;
    }

    if (gridApi.getEditingCells().length > 0) {
      onSaveAction();
    }

    actionsControl.value.editDisabled = getSelectedRows(gridApi).length !== 1;
    actionsControl.value.deleteDisabled = getSelectedRows(gridApi).length !== 1;
    actionsControl.value.duplicateDisabled = getSelectedRows(gridApi).length !== 1;
  }

  function onCellContextMenu(event: CellContextMenuEvent) {
    if (isEditing.value) return;
    gridApi.forEachNode((rn) => {
      if (rn.id == event.node.id) {
        rn.setSelected(true, true);
      }
    });
  }

  function onCreateAction() {
    handleCreateAction();
  }

  function onCreateChildAction(
    parentNode: IRowNode,
    setParentId?: (newRowData: { [k: string]: any; }, parentNode: IRowNode) => void) {
    if (actionsControl.value.createDisabled) return;

    if (gridWrapperProps.customCreateAction) {
      $emits("create-action");
      return;
    }

    const gridDisplayedColumns = gridApi.getAllDisplayedColumns();

    const colKeys = gridDisplayedColumns
      .filter((x) => x.getColDef().editable && x.getColId())
      .map((x) => x.getColId());

    if (colKeys.length > 0) {
      isUpdating.value = false;

      const createDefaultValueEvaluated: {
        [k: string]: any;
      } = {};

      if (typeof gridWrapperProps.createDefaultValue === "object") {
        for (const propertyName in gridWrapperProps.createDefaultValue) {
          const property = gridWrapperProps.createDefaultValue[propertyName];
          createDefaultValueEvaluated[propertyName] = typeof property === "function" ? property() : property;
        }
      }

      if (isDefined(setParentId)) {
        setParentId(createDefaultValueEvaluated, parentNode);
      } else {
        createDefaultValueEvaluated.parentId = parentNode.data.id;
      }

      const txResult = gridApi.applyServerSideTransaction({
        route: parentNode.getRoute(),
        add: [createDefaultValueEvaluated],
        addIndex: 0
      });

      const addedRow = txResult && txResult.add && txResult.add[0] ? txResult.add[0] : null;
      if (addedRow != null && addedRow.rowIndex != null) {
        const addedRowIndex = addedRow.rowIndex;

        for (let i = 0; i < colKeys.length; ++i) {
          gridApi.startEditingCell({ rowIndex: addedRowIndex, colKey: colKeys[i] });
        }

        // scroll to first column
        gridApi.ensureColumnVisible(gridDisplayedColumns[0].getColId());

      } else {
        console.error("Create child for group action failed. Row was not added.", txResult);
      }

      // force dispach event to trigger `rowValueChanged` method
      gridApi.dispatchEvent({ type: "rowValueChanged" });

      $emits("create-action");
    }
  }

  function handleCreateAction(rowDataTemplate: any = null) {
    if (actionsControl.value.createDisabled) return;

    if (gridWrapperProps.customCreateAction) {
      $emits("create-action");
      return;
    }

    const gridDisplayedColumns = gridApi.getAllDisplayedColumns();

    const colKeys = gridDisplayedColumns
      .filter((x) => x.getColDef().editable && x.getColId())
      .map((x) => x.getColId());

    if (colKeys.length > 0) {
      isUpdating.value = false;

      let createDefaultValueEvaluated: {
        [k: string]: any;
      } = {};

      if (rowDataTemplate !== null && typeof rowDataTemplate === "object") {
        createDefaultValueEvaluated = { ...rowDataTemplate };
      } else {
        if (typeof gridWrapperProps.createDefaultValue === "object") {
          for (const propertyName in gridWrapperProps.createDefaultValue) {
            const property = gridWrapperProps.createDefaultValue[propertyName];
            createDefaultValueEvaluated[propertyName] = typeof property === "function" ? property() : property;
          }
        }
      }

      gridApi.setGridOption("pinnedTopRowData", [createDefaultValueEvaluated]);
      const topRowNode = gridApi.getPinnedTopRow(0);

      (gridApi as any).gridBodyCtrl.eTop.style.height = `${topRowNode?.rowHeight}px`;
      (gridApi as any).gridBodyCtrl.eTop.style.minHeight = `${topRowNode?.rowHeight}px`;

      for (let i = 0; i < colKeys.length; ++i) {
        gridApi.startEditingCell({ rowIndex: 0, colKey: colKeys[i], rowPinned: "top" });
      }

      // scroll to first column
      gridApi.ensureColumnVisible(gridDisplayedColumns[0].getColId());

      // force dispach event to trigger `rowValueChanged` method
      gridApi.dispatchEvent({ type: "rowValueChanged" });

      $emits("create-action");
    }
  }

  /**
   * Get row node for action. If contextActionsRowNode is provided, it will be used.
   * Otherwise, if there is only one selected node, it will be used.
   *
   * @param actionButtonRowNode Grid has extra actions column with buttons.
   * This parameter is used to get row node for one of this actions.
   */
  function getRowNodeForAction(actionButtonRowNode: IRowNode | undefined | null = null): IRowNode | undefined | null {
    if (isDefined(actionButtonRowNode)) {
      return actionButtonRowNode;
    }
    const nodes = getSelectedNodes(gridApi);
    if (nodes.length === 1) {
      return nodes[0];
    }
    return null;
  }

  function onEditAction(event: Event, actionButtonRowNode: IRowNode | undefined | null = null) {
    if (gridWrapperProps.customEditAction) {
      $emits("edit-action");
      return;
    }

    const node = getRowNodeForAction(actionButtonRowNode);
    contextRowNode.value = node;

    const focusedColumn = gridApi.getFocusedCell()?.column;
    const colKeys = gridApi
      .getAllDisplayedColumns()
      .filter((x) => x.getColDef().editable && x.getColId())
      .map((x) => x.getColId());

    if (isDefined(node) && colKeys.length > 0) {
      isUpdating.value = true;
      const colKey = focusedColumn?.getColDef().editable === true ? focusedColumn?.getColId() ?? colKeys[0] : colKeys[0];

      gridApi.startEditingCell({ rowIndex: node.rowIndex!, colKey });

      $emits("edit-action");
    }
  }

  function onDuplicateAction(event: Event, actionButtonRowNode: IRowNode | undefined | null = null) {

    if (gridWrapperProps.customDuplicateAction) {
      $emits("duplicate-action");
      return;
    }

    const node = getRowNodeForAction(actionButtonRowNode);
    contextRowNode.value = node;

    if (isDefined(node)) {
      const newData = { ...node.data };

      // clear audit trial fields
      newData["createdBy"] = null;
      newData["createdAt"] = null;
      newData["modifiedBy"] = null;
      newData["modifiedAt"] = null;

      // clear id
      newData.id = null;

      handleCreateAction(newData);
    }
  }

  function onDeleteAction(event: Event, actionButtonRowNode: IRowNode | undefined | null = null) {
    if (actionsControl.value.deleteDisabled) return;
    if (gridWrapperProps.customDeleteAction) {
      $emits("delete-action");
      return;
    }

    const node = getRowNodeForAction(actionButtonRowNode);
    contextRowNode.value = node;

    deleteDialog.value = true;
  }

  async function onDeleteCancel() {
    contextRowNode.value = null;
  }

  async function onDeleteConfirm() {
    const node = contextRowNode.value;

    deleteDialog.value = false;


    if (isDefined(node)) {
      try {
        if (gridWrapperProps.serverSide) {
          await gridWrapperProps?.serverSideDatasource?.remove(node.data);
        }
        if (gridWrapperProps.customDeleteAction) {
          $emits("delete-action");
          return;
        }
        if (gridWrapperProps.serverSide) {
          const parentGroupKeys = (node as RowNode | undefined)?.getGroupKeys(true) ?? [];
          gridApi.refreshServerSide({ route: parentGroupKeys });
        } else {
          gridApi.applyTransaction({ remove: [node.data] });
        }
      } catch (e) {
        errorsStore.handleError(e);
      } finally {
        contextRowNode.value = null;
      }
    }
  }

  function onRefreshAction() {
    if (actionsControl.value.refreshDisabled) return;
    if (gridWrapperProps.serverSide) {
      const selectedNodes = getSelectedNodes(gridApi);
      const parentGroupKeys = (selectedNodes[0] as RowNode | undefined)?.getGroupKeys().filter((x) => x !== null) ?? [];
      gridApi.refreshServerSide({ route: parentGroupKeys, purge: true });
    }
    $emits("refresh-action");
  }

  function getColumnsWithInvalidValidationStatus(): Column[] {
    const columnsWithInvalidStatus = gridApi
      .getAllDisplayedColumns()
      .map((column) => {
        const columnCellEditorInstances = gridApi.getCellEditorInstances({ columns: [column] });
        if (columnCellEditorInstances && columnCellEditorInstances.length === 1) {
          const customCellEditorInstance = columnCellEditorInstances[0] as Partial<ICustomCellEditor>;

          if (customCellEditorInstance && "isValid" in customCellEditorInstance) {
            let isValid = null;
            if (typeof customCellEditorInstance.isValid === "function") {
              isValid = customCellEditorInstance.isValid();
            }

            if (typeof customCellEditorInstance.isValid === "boolean") {
              isValid = customCellEditorInstance.isValid;
            }

            if (isDefined(isValid) && !isValid) {
              return column;
            }
          }
        } else {
          console.debug(`Cell editor is not found or it is more instances that one for given column : ${column.getColId()}`);
        }

        return null;
      })
      .filter((column) => column !== null && column !== undefined) as Column[];

    return columnsWithInvalidStatus;
  }

  function onSaveAction() {
    const invalidColumns = getColumnsWithInvalidValidationStatus();

    /*
      When client validation does not pass do not try save server request
      and set horizontal scroll to the problematic column
    */
    if (invalidColumns && invalidColumns.length > 0) {
      console.debug("Save action is disabled due to invalid columns");
      gridApi.dispatchEvent({ type: "invalidColumnScroll", invalidColumns } as IInvalidColumnScrollEvent);
      return;
    }

    gridApi.stopEditing();
  }

  function onCancelAction() {
    cancelBtnClicked.value = true;
    gridApi.stopEditing(true);
    $emits("cancel-action");
  }


  function onRowEditingStarted(event: RowEditingStartedEvent) {
    contextRowNode.value = event.node as RowNode;
    previousRowData.value = { ...event.data };
  }

  async function onRowEditingStopped(event: RowEditingStoppedEvent) {
    try {
      if (!rowValueChanged.value || cancelBtnClicked.value) {

        // revert changes in grid row model for adding new row (normal)
        if (event.rowPinned === "top") {
          gridApi.setGridOption("pinnedTopRowData", []);
        }

        // revert changes in grid row model for adding new row (adding child)
        if (!isDefined(event.rowPinned)) {
          const isEditMode = !!event.data.id;
          if (!isEditMode) {
            const route = (event.node.parent as RowNode).getGroupKeys();

            gridApi.refreshServerSide({ route: route, purge: true });
          }
        }

        return;
      }
      if (event.data && gridWrapperProps.serverSide) {
        const isEditMode = !!event.data.id;
        const parentIdPropertyName = gridWrapperProps?.getParentIdPropertyName ? gridWrapperProps.getParentIdPropertyName() : "parentId";

        try {
          if (isEditMode) {
            lastEditedRowId.value = await gridWrapperProps?.serverSideDatasource?.update(event.data);
            const data = await gridWrapperProps?.serverSideDatasource?.getSingle(lastEditedRowId.value);
            $emits("single-row-refreshed", data);

            // refresh parent group if parent has changed
            const newParentNode = event.api.getRowNode(event.data[parentIdPropertyName]);
            const oldParentNode = event.node.parent;
            const hasParentChanged = newParentNode?.id !== oldParentNode?.id;
            if (hasParentChanged) {
              // refresh old parent group
              const oldParentGroupKeys = (oldParentNode as RowNode).getGroupKeys();
              gridApi.refreshServerSide({ route: oldParentGroupKeys, purge: false });

              if (newParentNode) {
                const parentGroupKeys = (newParentNode as RowNode).getGroupKeys().filter((x) => x !== null);
                // refresh parent group
                gridApi.refreshServerSide({ route: parentGroupKeys, purge: false });
              } else {
                // refresh all if parent is null
                gridApi.refreshServerSide({ route: [], purge: true });
              }
            }
          } else {
            lastEditedRowId.value = await gridWrapperProps?.serverSideDatasource?.create(event.data);
            const data = await gridWrapperProps?.serverSideDatasource?.getSingle(lastEditedRowId.value);
            $emits("single-row-refreshed", data);

            // refresh if create child (parentId is set).
            if (event.data[parentIdPropertyName]) {
              // if parent row node exists in grid (loaded) group keys path, otherwise refresh all everyting
              const parentRowNode = gridApi.getRowNode(event.data[parentIdPropertyName]);
              const parentGroupKeys = parentRowNode ? (parentRowNode as RowNode).getGroupKeys().filter((x) => x !== null) : [];

              gridApi.refreshServerSide({ route: parentGroupKeys, purge: false });
            }
            // refresh all if parent is null
            if (!event.data[parentIdPropertyName]) {
              gridApi.refreshServerSide({ route: [], purge: false });
            }
          }

          $emits("save-action", event.data);
          if (event.rowPinned === "top") {
            gridApi.setGridOption("pinnedTopRowData", []);
          }
          previousRowData.value = null;
          rowValueChanged.value = false;
        } catch (e) {
          const colKeys = gridApi.getAllDisplayedColumns()
            .filter((x) => x.getColDef().editable && x.getColId())
            .map((x) => x.getColId());

          const focusedColKey = gridApi.getFocusedCell()?.column.getColId();
          const colKey = focusedColKey ?? colKeys[0];
          if (isEditMode) {
            if (event.node.rowIndex != null && colKeys.length > 0) {
              gridApi.startEditingCell({ rowIndex: event.node.rowIndex, colKey });
            }
          } else {
            if (event.rowPinned === "top") {
              if (colKeys.length > 0) {
                gridApi.startEditingCell({ rowIndex: 0, colKey, rowPinned: "top" });
              }
            }

            if (!isDefined(event.rowPinned)) {
              if (event.node.rowIndex != null && colKeys.length > 0) {
                gridApi.startEditingCell({ rowIndex: event.node.rowIndex, colKey });
              }
            }
          }
          errorsStore.handleError(e);
        }
      }
    } finally {
      contextRowNode.value = null;
      cancelBtnClicked.value = false;
    }
  }

  function onRowValueChanged(_: RowValueChangedEvent) {
    rowValueChanged.value = true;
  }

  function onStoreRefreshed({ api }: any) {
    if (lastEditedRowId.value) {
      const node = gridApi.getRowNode(lastEditedRowId.value);
      if (node) {
        node.setSelected(true, true);
        if (node.rowIndex) {
          api.ensureIndexVisible(node.rowIndex, "middle");
        }
        if (gridWrapperProps?.expandAfterCreate) {
          api.setRowNodeExpanded(node, true);
        }
      }
      lastEditedRowId.value = null;
    }
  }

  function refreshSingleRow(id: string) {
    if (!gridWrapperProps.serverSide || !gridWrapperProps?.serverSideDatasource) {
      throw new Error("This method is only available for server side grid");
    }
    gridWrapperProps.serverSideDatasource.getSingle(id).then((data) => {
      if (data) {
        gridApi.forEachNode((node) => {
          if (node.data.id === id) {
            node.updateData(data);
            $emits("single-row-refreshed", data);
          }
        });
      } else {
        console.warn(`Could not find row with id ${id} to refresh!`);
      }
    });
  }

  return {
    onGridReady,
    actionsControl,
    deleteDialog,
    onCreateAction,
    onCreateChildAction,
    onEditAction,
    onDeleteAction,
    onDeleteCancel,
    onDeleteConfirm,
    onRefreshAction,
    onSaveAction,
    onCancelAction,
    onDuplicateAction,
    isEditing,
    isUpdating,
    contextRowNode,
    refreshSingleRow
  };
};
