<script lang="ts">
import { IControlledCellEditorParams, resolveValueRef, useValueChangeControl } from "@/components/Grid/UseValueChangeControl";
import CostCatalogueItemPicker from "@/components/CostCatalogue/CostCatalogueItemPicker.vue";
import { BodyScrollEvent, ICellEditorParams } from "ag-grid-community";
import { computed, onMounted, onUnmounted, ref, toRef } from "vue";
import { VForm } from "vuetify/lib/components/index.mjs";
import { AgGridFilterModel } from "@masta/generated-model";

export interface ICostCatalogueItemPickerCellEditorParams extends IControlledCellEditorParams, ICellEditorParams {
  rules?: any[],
  agGridFilterModel?: (() => AgGridFilterModel) | null | undefined;
}

// Warn: Use export default instead of <script setup /> because only this works with AgGrid!
export default {
  components: { CostCatalogueItemPicker },
  setup(props: {
    params: ICostCatalogueItemPickerCellEditorParams;
  }) {
    const costCatalogueItemPickerRef = ref<typeof CostCatalogueItemPicker>();
    const formRef = ref<VForm>();
    const valid = ref<boolean>();

    const params = toRef(props, "params");

    const isEditEnabled = toRef(props.params.isEditEnabled ? props.params.isEditEnabled(props.params) : true);

    const initialValue = props.params.value;
    const costCatItemValue = resolveValueRef(props.params, initialValue);

    const rules = computed(() => {
      return params.value.rules ?? [];
    });

    const agGridFilterModel = computed(() => {
      return params.value.agGridFilterModel ?? null;
    });

    const errors = computed(() => {
      return formRef.value?.errors.map((e) => e.errorMessages.join(",")).join(",");
    });

    const showTooltip = computed<boolean>(() => !!errors.value && !!costCatalogueItemPickerRef.value?.hasFocus());

    function getValue() {
      return costCatItemValue.value;
    }

    function afterGuiAttached() {
      if (props.params.cellStartedEdit) {
        focusIn();
      }
    }

    function focusIn() {
      costCatalogueItemPickerRef.value?.focus();
    }

    function focusOut() {
    }

    function isValid() {
      return isEditEnabled.value ? valid?.value : undefined;
    }

    onMounted(() => {
      props.params.api.addEventListener("bodyScroll", onGridBodyScroll);
    });

    onUnmounted(() => {
      const { api } = props.params;
      if (api && !api.isDestroyed()) {
        api.removeEventListener("bodyScroll", onGridBodyScroll);
      }
    });

    function onGridBodyScroll(event: BodyScrollEvent) {
      // close menu on scroll (when is open) to avoid menu position issues
      if (costCatalogueItemPickerRef.value && costCatalogueItemPickerRef.value.getMenu()) {
        costCatalogueItemPickerRef.value.setMenu(false);
      }
    }

    useValueChangeControl(costCatItemValue, props.params);

    return {
      getValue,
      afterGuiAttached,
      focusIn,
      focusOut,
      isValid,
      showTooltip,
      rules,
      valid,
      errors,
      isEditEnabled,
      costCatItemValue,
      costCatalogueItemPickerRef: costCatalogueItemPickerRef as any,
      formRef: formRef as any,
      agGridFilterModel
    };
  }
};
</script>


<template>
  <v-tooltip v-model="showTooltip" location="top" color="error" :offset="30" class="cost-cat-item-picker-cell-editor-tooltip">
    <template #activator="act">
      <v-form ref="formRef" v-model="valid" :disabled="!isEditEnabled" class="cost-cat-item-picker-cell-editor">
        <div class="cost-cat-item-picker-cell-editor-container">
          <CostCatalogueItemPicker
            ref="costCatalogueItemPickerRef"
            v-model="costCatItemValue"
            color="primary"
            :readonly="!isEditEnabled"
            :placeholder="params.placeholder"
            :rules="rules"
            :ag-grid-filter-model="agGridFilterModel"
          />
          <div v-bind="act.props" />
        </div>
      </v-form>
    </template>
    <span v-if="errors">{{ errors }}</span>
  </v-tooltip>
</template>


<style lang="scss">
.cost-cat-item-picker-cell-editor {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .cost-cat-item-picker-cell-editor-container {
    padding: 0.31em;

    .v-input {
      --v-input-chips-margin-top: 0;
      --v-input-chips-margin-bottom: 0;
      font-size: 1em;
    }

    .v-autocomplete {
      &--single {
        .v-field--variant-outlined input {
          --v-input-chips-margin-top: 2px;
          --v-input-chips-margin-bottom: 2px;
        }
      }

      .v-field {
        border-radius: 8px;
        font-size: 1em;

        .v-field__overlay {
          background-color: white;
        }

        .v-field__input {
          font-size: 1em;
          min-height: 34px;
        }

        .v-field__outline {
          --v-field-border-width: 2px;
        }

        color: rgba(0, 0, 0, 0.5);

        &:hover {
          color: var(--ag-data-color);
        }

        &.v-field--focused {
          color: var(--ag-data-color);
        }
      }
    }
  }
}

.cost-cat-item-picker-cell-editor-tooltip {
  .v-overlay__content {
    background: rgba(var(--v-theme-error), 0.7);
  }
}
</style>
