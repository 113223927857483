<script setup lang="ts">
import ApiService from "@/services/api";
import { $t } from "@/i18n";
import { MaterialSubType, MeasurementUnit, ProductTemplateDetailsDto, ResourceInfoDto, ResourcePurpose, ResourceSubType, ResourceType } from "@masta/generated-model";
import { computed, ref, watch } from "vue";
import ResourcePicker from "@/components/Resources/ResourcePicker.vue";
import { replaceTemplateResource, ProductTemplateDraft } from "@/components/ProcessDrafts/ProcessDraftModels";
import {
  createMaterialInfo,
  createDraftFromTemplate,
  createEmptyProductTemplateDraft,
  createStubProductTemplateDraftForResource
} from "@/components/ProcessDrafts/model-from-product-template";
import { getEnumTitleValuePairs } from "@/composables/enumHelpers";
import { translateMeasurementUnit, translateResourcePurpose, translateResourceSubType } from "@/composables/translateEnum";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { VForm } from "vuetify/components";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { hasProductionPurpose } from "@/components/ProcessDrafts/validation";
import { useScenariosStore } from "@/store/ScenariosStore";

const modelValue = defineModel<boolean>({ required: true });
const props = defineProps<{
  targetProduct: ResourceInfoDto;
}>();

const emit = defineEmits<{
  (e: "dialog-result", result: ProductTemplateDraft | null): void;
}>();

const outcomeResource = ref<ResourceInfoDto | null>(null);
const sourceResource = ref<ResourceInfoDto | null>(null);
const processLoadProblem = ref(false);
const form = ref<InstanceType<typeof VForm> | null>(null);
const valid = ref<boolean>(false);
const showProgress = ref(false);
const snackbarsStore = useSnackbarsStore();
const scenarioStore = useScenariosStore();

watch(
  () => modelValue.value,
  (newValue) => {
    if (newValue) {
      clearValues();
    }
  }
);

watch(
  () => sourceResource.value,
  () => (processLoadProblem.value = false)
);

function onCancel() {
  close();
  emit("dialog-result", null);
}

function clearValues() {
  showProgress.value = false;
  sourceResource.value = null;
  outcomeResource.value = null;
  processLoadProblem.value = false;
}

async function onAdd() {
  const result = await form.value?.validate();
  if (!result || !result.valid) {
    return;
  }
  showProgress.value = true;
  await create();
  showProgress.value = false;
}

function closeWithProductTemplateDraft(task: ProductTemplateDraft) {
  close();
  emit("dialog-result", task);
}

function createProductTemplateDraftFromTemplates(productTemplates: ProductTemplateDetailsDto[]) {
  const root = productTemplates.find((x) => x.id === x.rootTaskId);
  if (!root) {
    throw new Error("There is no root template");
  }
  if (root.resourceId !== sourceResource.value?.id) {
    throw new Error("Template is not referencing selected resource");
  }
  return createDraftFromTemplate(root, productTemplates, true);
}

function close() {
  modelValue.value = false;
}

async function create() {
  let productTemplateDraft: ProductTemplateDraft | null = null;
  if (!outcomeResource.value) {
    return;
  }
  if (sourceResource.value) {
    productTemplateDraft = await createProductTemplateDraftForResource(sourceResource.value, false);
    if (!productTemplateDraft) {
      snackbarsStore.createSnackbar({
        message: $t("processDraft-addProductDialog-cannotFindValidTemplateForMaterialError-message", { $: "Could not find valid template for source material." }),
        type: "error",
        closeable: true,
        position: "top-right"
      });
      processLoadProblem.value = true;
      form.value?.validate();
      return;
    }
    replaceTemplateResource(productTemplateDraft, sourceResource.value, outcomeResource.value);
  }

  if (!productTemplateDraft) {
    productTemplateDraft = createStubProductTemplateDraftForResource(outcomeResource.value);
  }
  closeWithProductTemplateDraft(productTemplateDraft);
}

async function createProductTemplateDraftForResource(res: ResourceInfoDto, shouldCreateEmpty = true) {
  const response = await ApiService.productTemplates.getCurrentForResource(res.id, res.scenarioId);

  if (response.data && response.data.templates && response.data.templates.length > 0) {
    return createProductTemplateDraftFromTemplates(response.data.templates);
  }
  if (shouldCreateEmpty) {
    return createStubProductTemplateDraftForResource(res);
  }
  return null;
}

function processLoadProblemRule(v: any): boolean | string {
  return !processLoadProblem.value || $t("processDrafts-edit-processLoadProblem-message", { $: "Cannot load valid process" });
}
</script>

<template>
  <v-dialog v-model="modelValue" :persistent="true" content-class="add-product-template-dialog">
    <v-form ref="form" v-model="valid" @submit.prevent="">
      <v-card class="pa-8">
        <v-card-title class="d-flex align-center justify-space-between">
          <div class="text-h4">{{ $t("processDrafts-addProductDialog-title-label", { $: "Add new task" }) }}</div>
          <v-icon @click="onCancel">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <ResourcePicker
                v-model="outcomeResource"
                class="flex-1-1-100"
                :readonly="false"
                :rules="[requiredRule]"
                :hide-details="'auto'"
                :label="$t('processDraft-addProductDialog-outcomeProduct-label', { $: 'Outcome Product' })"
                :resource-types="[ResourceType.Material]"
                :resource-sub-types="[ResourceSubType.MaterialSemiFinishedOrFinalProduct]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ResourcePicker
                v-model="sourceResource"
                class="flex-1-1-100"
                :readonly="false"
                :rules="[processLoadProblemRule]"
                :hide-details="'auto'"
                :label="$t('processDraft-addProductDialog-sourceProduct-label', { $: 'Source of Technology Template' })"
                :resource-types="[ResourceType.Material]"
                :resource-sub-types="[ResourceSubType.MaterialSemiFinishedOrFinalProduct]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn class="ma-4" variant="elevated" color="secondary" block @click="onCancel">
                {{ $t("processDrafts-addProductDialog-cancel-action", { $: "Cancel" }) }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn class="ma-4" variant="elevated" color="primary" block :loading="showProgress" @click="onAdd">
                {{ $t("processDrafts-addProductDialog-add-action", { $: "Add" }) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<style lang="scss">
.add-product-template-dialog {
  width: 30vw !important;
}

@media screen and (max-width: 1900px) {
  .add-product-template-dialog {
    width: 50vw !important;
  }
}

@media screen and (max-width: 1450px) {
  .add-product-template-dialog {
    width: 75vw !important;
  }
}
</style>
