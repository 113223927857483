<script lang="ts" setup>
import AddProductDialog from "@/components/ProcessDrafts/AddProductDialog.vue";
import { autoFillConsumables, autoFillProducts } from "@/components/ProcessDrafts/auto-fill";
import { mapCreateProductTemplateDraftsFromModel } from "@/components/ProcessDrafts/mapping";
import ProcessDraftEditorActionsBtn from "@/components/ProcessDrafts/ProcessDraftEditorActionsBtn.vue";
import { copyTemplate, moveDownTemplate, moveUpTemplate, ProcessDraft, ProductTemplateDraft, recalculateWbsTopLevel } from "@/components/ProcessDrafts/ProcessDraftModels";
import { filterValidationError, ProcessValidationError } from "@/components/ProcessDrafts/ProcessValidationError";
import ProductTemplateDraftEditor from "@/components/ProcessDrafts/ProductTemplateDraftEditor.vue";
import { nameRules, validateProcessDraft } from "@/components/ProcessDrafts/validation";
import ResourcePicker from "@/components/Resources/ResourcePicker.vue";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { translateProcessDraftStatus } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { useAuthStore } from "@/store/AuthStore";
import { useErrorsStore } from "@/store/ErrorsStore";
import { useProcessDraftsStore } from "@/store/ProcessDraftsStore";
import { useSnackbarsStore } from "@/store/SnackbarsStore";
import { ProcessDraftStatus, ResourceInfoDto, ResourceSubType, ResourceType } from "@masta/generated-model";
import { isDefined, watchDebounced } from "@vueuse/core";
import equal from "fast-deep-equal";
import { cloneDeep } from "lodash";
import { storeToRefs } from "pinia";
import { computed, onMounted, onUnmounted, ref, watch, WatchStopHandle } from "vue";
import { useRouter } from "vue-router";
import { VForm } from "vuetify/components";
import { useScenariosStore } from "@/store/ScenariosStore";

const props = defineProps<{
  processDraft: ProcessDraft | null;
  readonly: boolean;
}>();
const emit = defineEmits<{
  (e: "saved", id: string): void;
  (e: "finished"): void;
  (e: "close"): void;
}>();

defineExpose({ save, hasChanges });
const router = useRouter();
const { currentTenantId } = storeToRefs(useAuthStore());
const form = ref<InstanceType<typeof VForm> | null>(null);
const productTemplateDrafts = ref<ProductTemplateDraft[]>([]);
const targetResource = ref<ResourceInfoDto | null>(null);
const name = ref<string>("");

const validationErrors = ref<ProcessValidationError[]>([]);
const processDraftsStore = useProcessDraftsStore();
const errorsStore = useErrorsStore();
const snackbarsStore = useSnackbarsStore();
const valid = ref<boolean>(false);
const changed = ref<boolean>(false);
const addDialog = ref(false);
const isRequireScrollOnTransition = ref<boolean>(false);

const filteredValidationErrors = computed(() => {
  return validationErrors.value.filter((x) => filterValidationError(x));
});

const bottomButtonsRef = ref();
const shouldShowBottomButtons = ref(false);
let resizeObserver: ResizeObserver = null!;

const productTemplateDraftEditorRefs = ref<InstanceType<typeof ProductTemplateDraftEditor>[]>([]);

const finished = ref(false);
const newId = ref<string>();

const productTemplateDraftsWatchStopHandle = ref<WatchStopHandle | null>(null);
const nameWatchStopHandle = ref<WatchStopHandle | null>(null);
const targetResourceWatchStopHandle = ref<WatchStopHandle | null>(null);

const processDraftStatus = computed(() => (props.processDraft?.status >= 0 ? translateProcessDraftStatus(props.processDraft?.status) : ""));
const processDraftStatusColor = computed(() => {
  switch (props.processDraft?.status) {
    case ProcessDraftStatus.WaitingForAi:
      return "blue";
    case ProcessDraftStatus.Archived:
      return "rgba(255,214,0,1)";
    default:
      return "rgba(168,232,255,0.87)";
  }
});

onMounted(() => {
  resizeObserver = new ResizeObserver(checkBottomButtonsPosition);
  resizeObserver.observe(bottomButtonsRef.value.$el as HTMLElement);
  form.value?.validate();
});

onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
  }
});

function hasChanges() {
  return changed.value || !isDefined(templateProcessId.value);
}

watch(
  () => props.processDraft,
  (newValue, oldValue) => {
    if (newValue) {
      /*
        We need that recalculate the WBS/postion because it is sth client specific thing.
        Without this, the newly set object will immediately be considered as changed!
       */
      recalculateWbsTopLevel(newValue.productTemplates);

      const newValueDeepCopy = cloneDeep(newValue);

      unregisterChangesWatches();

      changed.value = false;
      name.value = newValueDeepCopy.name ?? "";
      productTemplateDrafts.value = [...newValueDeepCopy.productTemplates];
      targetResource.value = newValueDeepCopy.targetResource;

      registerChangesWatches();
    } else {
      name.value = "";
      productTemplateDrafts.value = [];
    }
  },
  { immediate: true }
);

/**
 * Unregister all process draft object changes watches.
 */
function unregisterChangesWatches() {
  if (productTemplateDraftsWatchStopHandle.value != null) productTemplateDraftsWatchStopHandle.value();
  if (nameWatchStopHandle.value != null) nameWatchStopHandle.value();
  if (targetResourceWatchStopHandle.value != null) targetResourceWatchStopHandle.value();
}

/**
 * Register all process draft object changes watches.
 */
function registerChangesWatches() {
  productTemplateDraftsWatchStopHandle.value = watch(
    productTemplateDrafts,
    (newValue, oldValue) => {
      const isEqualValue = equal(newValue, props.processDraft?.productTemplates);
      if (!isEqualValue) {
        changed.value = true;
      }
    },
    { deep: true }
  );

  nameWatchStopHandle.value = watch(name, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      changed.value = true;
    }
  });

  targetResourceWatchStopHandle.value = watch(targetResource, (newValue, oldValue) => {
    if (!equal(newValue, oldValue)) {
      changed.value = true;
    }
  });
}

const templateProcessId = computed(() => props.processDraft?.id ?? newId.value);

function addTask() {
  addDialog.value = true;
}

function removeTask(task: ProductTemplateDraft) {
  productTemplateDrafts.value = productTemplateDrafts.value.filter((x) => x !== task);
}

function onMoveUpTask(task: ProductTemplateDraft) {
  moveUpTemplate(productTemplateDrafts.value, task);
}

function onMoveDownTask(task: ProductTemplateDraft) {
  moveDownTemplate(productTemplateDrafts.value, task);
}

function onCopyTask(task: ProductTemplateDraft) {
  copyTemplate(productTemplateDrafts.value, task);
}

function expandAll() {
  productTemplateDraftEditorRefs.value.forEach((editor) => {
    editor.openPanel();
  });
}

function collapseAll() {
  productTemplateDraftEditorRefs.value.forEach((editor) => {
    editor.closePanel();
  });
}

function closeDetails() {
  emit("close");
}

async function performSave(finish: boolean) {
  try {
    if (!props.processDraft) {
      return;
    }

    if (!templateProcessId.value) {
      const response = await processDraftsStore.create({
        scenarioId: useScenariosStore().selectedScenario?.id ?? "",
        name: name.value ?? "",
        productTemplates: mapCreateProductTemplateDraftsFromModel(productTemplateDrafts.value),
        createProductTemplates: finish,
        createFromAi: null,
        targetResourceId: targetResource.value?.id ?? null,
        targetResourceBusinessId: null
      });
      newId.value = response.data;
      changed.value = false;
      emit("saved", response.data);
    } else if (!props.processDraft || props.processDraft.status !== ProcessDraftStatus.Archived) {
      await processDraftsStore.update({
        id: templateProcessId.value,
        scenarioId: useScenariosStore().selectedScenario?.id ?? "",
        name: name.value ?? "",
        productTemplates: mapCreateProductTemplateDraftsFromModel(productTemplateDrafts.value),
        targetResourceId: targetResource.value?.id ?? null,
        createProductTemplates: finish
      });
      changed.value = false;
      emit("saved", templateProcessId.value);
    }
  } catch (e) {
    errorsStore.handleError(e);
    return false;
  }
  return true;
}

async function save() {
  return await performSave(false);
}

function showProductTemplates() {
  if (!templateProcessId.value) {
    return;
  }
  router.push({
    name: "ProductTemplates",
    params: {
      scenarioId: useScenariosStore().scenarioBusinessId
    },
    query: {
      draft: templateProcessId.value
    }
  });
}

async function onValidate() {
  if (await validate()) {
    snackbarsStore.createSnackbar({
      message: $t("processDraft-view-validationPassed-message", { $: "Validation passed" }),
      type: "info",
      closeable: true,
      position: "top-right"
    });
  }
}

watchDebounced(
  computed(() => form.value?.errors),
  () => {
    checkProductTemplateDraftsError();
  },
  { debounce: 100 }
);

function checkProductTemplateDraftsError() {
  if (form.value?.$el) {
    const elements = form.value?.$el.getElementsByClassName("segment-error");
    for (const element of elements) {
      element.classList.remove("segment-error");
    }
  }
  if (form.value && form.value.items) {
    const errorInputs = form.value.items.filter((x) => typeof x.isValid === "boolean" && x.isValid === false);
    for (const errorInput of errorInputs) {
      const element = document.getElementById(errorInput.id!);
      let parentElement = element?.closest(".resource-spec-editor-row");
      parentElement?.classList.add("segment-error");
      parentElement = element?.closest(".step-editor .node-header");
      parentElement?.classList.add("segment-error");
      parentElement = element?.closest(".task-editor .node-header");
      parentElement?.classList.add("segment-error");
    }
  }
}

async function validate() {
  validationErrors.value = [];

  const result = await form.value?.validate();
  checkProductTemplateDraftsError();
  if (!result || !result.valid) {
    snackbarsStore.createSnackbar({
      message: $t("processDraft-view-formValidationFailed-message", { $: "Validation failed" }),
      type: "error",
      closeable: true,
      position: "top-right"
    });
    return false;
  }

  const processDraft: ProcessDraft = {
    ...props.processDraft,
    productTemplates: productTemplateDrafts.value,
    targetResource: targetResource.value,
    name: "",
    status: ProcessDraftStatus.WorkInProgress
  };
  validationErrors.value = validateProcessDraft(processDraft, { directiveResourceType: ResourceType.Equipment });
  if (validationErrors.value.length > 0) {
    snackbarsStore.createSnackbar({
      message: validationErrors.value
        .map((x) => {
          return typeof x === "string" ? x : x.message;
        })
        .join("\n"),
      type: "error",
      closeable: true,
      position: "top-right"
    });
    return false;
  }
  return true;
}

async function finishAndCreateTemplates() {
  if (!(await validate())) {
    return;
  }

  const result = await performSave(true);
  if (result) {
    finished.value = true;
    snackbarsStore.createSnackbar({
      message: $t("processDraft-view-finishCreatingTemplates-message", { $: "Product technology created" }),
      type: "info",
      closeable: true,
      position: "top-right"
    });
    emit("finished");
  }
}

function checkBottomButtonsPosition() {
  const container = bottomButtonsRef.value.$el as HTMLElement;
  shouldShowBottomButtons.value = container?.scrollHeight > window.innerHeight;
}

function onAutoFillConsumables() {
  autoFillConsumables(productTemplateDrafts.value);
  snackbarsStore.createSnackbar({
    message: $t("processDraft-view-autoFillConsumablesCompleted-message", { $: "Auto-filling consumables completed" }),
    type: "info",
    closeable: true,
    position: "top-right"
  });
}

function onAddDialogResult(result: ProductTemplateDraft | null) {
  if (result) {
    isRequireScrollOnTransition.value = true;
    productTemplateDrafts.value.push(result);
    recalculateWbsTopLevel(productTemplateDrafts.value);
  }
}

function onTaskListTransitionAfterEnter(element: HTMLElement) {
  if (isRequireScrollOnTransition.value) {
    element.scrollIntoView({ behavior: "smooth" });
    element.classList.add("highlight-task");

    isRequireScrollOnTransition.value = false;
  }
}

function onAutoFillProducts() {
  autoFillProducts(productTemplateDrafts.value);
  snackbarsStore.createSnackbar({
    message: $t("processDraft-view-autoFillProductsCompleted-message", { $: "Auto-filling products completed" }),
    type: "info",
    closeable: true,
    position: "top-right"
  });
}
</script>

<template>
  <v-form ref="form" v-model="valid" class="process-draft-editor" @submit.prevent="">
    <v-row ref="bottomButtonsRef" class="mt-4">
      <v-col cols="12">
        <v-row class="align-center">
          <v-col cols="5">
            <v-text-field
              v-model="name"
              :readonly="props.readonly"
              variant="outlined"
              density="compact"
              hide-details="auto"
              :label="$t('processDraft-view-name-label', { $: 'Revision Name' })"
              :rules="nameRules"
              style="font-size: 8px"
            />
          </v-col>
          <v-col cols="5" align-self="center">
            <ResourcePicker
              v-model="targetResource"
              class="flex-1-1-100"
              :label="$t('processDraft-view-targetProduct-label', { $: 'Target Product' })"
              :readonly="props.readonly"
              :resource-types="[ResourceType.Material]"
              :resource-sub-types="[ResourceSubType.MaterialSemiFinishedOrFinalProduct]"
              :rules="[requiredRule]"
              :hide-details="'auto'"
            />
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <v-chip class="flex-grow-1" variant="flat" :color="processDraftStatusColor" size="x-large">{{ processDraftStatus }}</v-chip>
            <v-btn icon="mdi-window-close" size="x-large" elevation="0" @click="closeDetails" />
          </v-col>
          <v-col class="d-flex">
            <v-btn :disabled="props.readonly || (!changed && templateProcessId != null)" variant="elevated" density="compact" color="primary" icon="mdi-content-save" @click="save">
            </v-btn>
            <v-btn variant="elevated" density="compact" color="primary" class="ml-4" :disabled="props.readonly" prepend-icon="mdi-plus" @click="addTask">
              {{ $t("processDraft-view-addTask-action", { $: "Add taks" }) }}
            </v-btn>
            <process-draft-editor-actions-btn
              :readonly="props.readonly"
              :on-auto-fill-consumables="onAutoFillConsumables"
              :on-auto-fill-products="onAutoFillProducts"
              :on-validate="onValidate"
              :on-finish-and-create-templates="finishAndCreateTemplates"
            >
            </process-draft-editor-actions-btn>
            <v-btn
              v-if="processDraft?.status == ProcessDraftStatus.Archived"
              variant="elevated"
              density="compact"
              color="primary"
              class="ml-4"
              prepend-icon="mdi-account-hard-hat"
              @click="showProductTemplates"
            >
              {{ $t("processDraft-view-showCreatedProductTemplates-action", { $: "Show Created Product Templates" }) }}
            </v-btn>
            <v-spacer class="flex-1-1" />
            <v-btn variant="plain" density="compact" prepend-icon="mdi-expand-all" class="ml-4" @click="expandAll">
              {{ $t("processDraft-view-expandAll-action", { $: "Expand all" }) }}
            </v-btn>
            <v-btn variant="plain" density="compact" prepend-icon="mdi-collapse-all" class="ml-4" @click="collapseAll">
              {{ $t("processDraft-view-collapseAll-action", { $: "Collapse all" }) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-slide-y-reverse-transition hide-on-leave>
        <v-col v-if="productTemplateDrafts.length === 0" cols="12" class="mt-16 text-center text-body-1 text-disabled">
          {{ $t("processDraft-view-noTasks-message", { $: "There are currenlty no tasks added" }) }}
        </v-col>
      </v-slide-y-reverse-transition>
      <v-col cols="12">
        <v-slide-y-transition group @after-enter="onTaskListTransitionAfterEnter">
          <template v-for="(template, index) in productTemplateDrafts" :key="index">
            <product-template-draft-editor
              ref="productTemplateDraftEditorRefs"
              v-model="productTemplateDrafts[index]"
              :validation-errors="validationErrors"
              :readonly="props.readonly"
              @remove="removeTask"
              @moveup="onMoveUpTask"
              @movedown="onMoveDownTask"
              @copy="onCopyTask"
            />
          </template>
        </v-slide-y-transition>
      </v-col>
      <v-col cols="12">
        <v-fade-transition>
          <v-card v-if="filteredValidationErrors.length" color="secondary" elevation="4" class="error-container">
            <v-card-text>
              <ul class="ma-8">
                <v-fade-transition group>
                  <li v-for="(e, index) in filteredValidationErrors" :key="index" class="text-red error">
                    <v-icon icon="mdi-alert-circle" />
                    {{ e.message }}
                  </li>
                </v-fade-transition>
              </ul>
            </v-card-text>
          </v-card>
        </v-fade-transition>
      </v-col>
      <v-fade-transition>
        <v-col v-if="shouldShowBottomButtons" cols="12">
          <v-row class="align-center">
            <v-col class="d-flex">
              <v-btn variant="elevated" density="compact" color="primary" :disabled="props.readonly" prepend-icon="mdi-plus" @click="addTask">
                {{ $t("processDraft-view-addTask-action", { $: "Add task" }) }}
              </v-btn>
              <process-draft-editor-actions-btn
                :readonly="props.readonly"
                :on-auto-fill-consumables="onAutoFillConsumables"
                :on-validate="onValidate"
                :on-finish-and-create-templates="finishAndCreateTemplates"
                :on-show-product-templates="showProductTemplates"
                :disabled-show-product-templates="!templateProcessId"
              >
              </process-draft-editor-actions-btn>
              <v-btn :disabled="props.readonly" variant="elevated" density="compact" color="primary" class="ml-4" prepend-icon="mdi-content-save" @click="save">
                {{ $t("processDraft-view-saveDraft-action", { $: "Save Draft" }) }}
              </v-btn>
              <v-spacer class="flex-1-1" />
              <v-btn variant="plain" density="compact" prepend-icon="mdi-expand-all" class="ml-4" @click="expandAll">
                {{ $t("processDraft-view-expandAll-action", { $: "Expand all" }) }}
              </v-btn>
              <v-btn variant="plain" density="compact" prepend-icon="mdi-collapse-all" class="ml-4" @click="collapseAll">
                {{ $t("processDraft-view-collapseAll-action", { $: "Collapse all" }) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-fade-transition>
    </v-row>
  </v-form>
  <add-product-dialog v-if="processDraft" v-model="addDialog" :target-product="processDraft.targetResource!" @dialog-result="onAddDialogResult" />
</template>

<style lang="scss">
.process-draft-editor {
  .gap-10 {
    gap: 10px;
  }

  .max-width-100 {
    max-width: 100px;
  }

  .max-width-200 {
    max-width: 200px;
  }

  .max-width-300 {
    max-width: 300px;
  }

  .max-width-500 {
    max-width: 500px;
  }

  .min-width-50 {
    min-width: 50px;
  }

  .min-width-100 {
    min-width: 100px;
  }

  .min-width-150 {
    min-width: 150px;
  }
}
</style>

<style scoped lang="scss">
@keyframes highlight-fade {
  0% {
    background-color: #d7cffc;
  }
  100% {
    background-color: #f5f5ff;
  }
}

.highlight-task,
.highlight-task > :first-child {
  animation: highlight-fade 1s ease-in 750ms;
}

.error-container {
  background-color: rgba(var(--v-theme-gantt-grey), 0.6) !important;

  .error {
    list-style-type: none;
    font-size: 16px;
  }
}

:deep(.segment-error) {
  border: 1px solid #ff5252 !important;
  background-color: #fff0f0 !important;
}
</style>
