import { ApiClient } from "@/services/api/common.api";
import {
  CreateOrganizationCommand,
  CreateOrganizationResponse,
  FindOrganizationResult,
  GetOrganizationsPaginatedQuery,
  GetOrganizationsQuery,
  GetOrganizationsResponse,
  IAgGridPaginationResponse,
  OrganizationDto,
  UpdateOrganizationCommand
} from "@masta/generated-model";

export default {
  async create(request: CreateOrganizationCommand) {
    return ApiClient.put<CreateOrganizationResponse>(`/organizations`, request);
  },
  async getSingle(id: string, scenarioId: string) {
    return ApiClient.get<OrganizationDto>(`/organizations/${id}?scenarioId=${scenarioId}`);
  },
  async getAll(request: GetOrganizationsQuery) {
    return ApiClient.get<GetOrganizationsResponse>(`/organizations/?scenarioId=${request.scenarioId}`);
  },
  async getAllPaginated(request: GetOrganizationsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<OrganizationDto>>(`/organizations`, request);

  },
  async update(request: UpdateOrganizationCommand) {
    return ApiClient.put<undefined>(`/organizations/${request.id}`, request);
  },
  async remove(id: string, scenarioId?: string) {
    return ApiClient.delete(`/organizations/${id}?scenarioId=${scenarioId}`);
  },
  async findOrganizations(q: string, scenarioId: string) {
    return ApiClient.get<FindOrganizationResult>(`/organizations/query?q=${encodeURIComponent(q)}&scenarioId=${q}`);
  }
};
