<script lang="ts" setup>
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { inject, ref, watch } from "vue";
import { CostCatalogueItemDto, MeasurementUnit, PurchaseOrderPredictionDto } from "@masta/generated-model";
import { ClientSideRowModelModule, KeyCreatorParams, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { $t } from "@/i18n";
import ApiService from "@/services/api";
import { useScenariosStore } from "@/store/ScenariosStore";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { translateMeasurementUnit } from "@/composables/translateEnum";
import { enumToEditorEntries, enumValueEntryWithLocaleComparator, translateEditorEntries } from "@/components/Grid/ColumnTypes";
import { requiredRule } from "@/components/ValueCellEditor/CommonValidationRules";
import { IControlledCellEditorParams } from "@/components/Grid/UseValueChangeControl";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { getCostCatalogueItemName } from "@/components/CostCatalogue/CostCatalogueItemNameFunction";

interface Props {
  purchaseOrderPredictionDtos: PurchaseOrderPredictionDto[];
}

const props = defineProps<Props>();

const scenariosStore = useScenariosStore();
const gridWrapperRef = ref<GridWrapperComponent>();

defineExpose({
  gridWrapperRef
});

// Bind the rowData to the prop value
const rowData = ref<PurchaseOrderPredictionDto[]>(props.purchaseOrderPredictionDtos ?? []);

watch(
  () => props.purchaseOrderPredictionDtos,
  (newData) => {
    if (Array.isArray(newData)) {
      rowData.value = [...newData];
      gridWrapperRef.value?.gridApi.refreshClientSideRowModel();
      gridWrapperRef.value?.gridApi.redrawRows();
    } else {
      console.error("Expected an array but got:", newData);
      rowData.value = [];
    }
  },
  { immediate: true }
);

const costCatalogueItems = ref<CostCatalogueItemDto[]>([]);

const defaultColumnDef = ref({
  floatingFilter: true,
  filterParams: {
    applyMiniFilterWhileTyping: true
  }
});

// Fetch the cost catalogue items
async function fetchCostCatalogueItems() {
  try {
    const response = (await ApiService.costCatalogue.getCostCatalogue(scenariosStore.selectedScenario?.id)) as any;
    costCatalogueItems.value = response.data.results;
  } catch (error) {
    console.error("Error fetching cost catalogue items:", error);
  }
}

fetchCostCatalogueItems();

const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const periodStartEditorRef = ref<string | null>(null);
const periodEndEditorRef = ref<string | null>(null);

// Update the columns
function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "itemId",
      type: "textInputTypeColumn",
      hide: true,
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: () => $t("purchaseOrderPrediction-itemId-label", { $: "Item ID" })
    },
    {
      field: "itemBusinessId",
      type: "textInputTypeColumn",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: () => $t("purchaseOrderPrediction-itemBusinessId-label", { $: "Item Business ID" })
    },
    {
      field: "description",
      type: "textInputTypeColumn",
      hide: true,
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: () => $t("purchaseOrderPrediction-description-label", { $: "Description" })
    },
    {
      field: "quantity",
      sortable: true,
      editable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: ["numberInputTypeColumn", "numberFloatingFilterColumnType"],
      cellEditorParams: {
        rules: [requiredRule],
        placeholder: $t("purchaseOrderPrediction-quantity-label", { $: "Quantity" })
      },
      headerValueGetter: (_: any) => $t("purchaseOrderPrediction-quantity-label", { $: "Quantity" })
    },
    {
      field: "quantityUnit",
      editable: false,
      sortable: true,
      resizable: true,
      type: ["enumTypeColumn", "setFloatingFilterColumnType"],
      valueFormatter: (params: any) => (params.data ? translateMeasurementUnit(params.data.quantityUnit) : null),
      headerValueGetter: (_: any) => $t("purchaseOrderPrediction-unit-label", { $: "Unit" }),
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => params.value.key,
        keyCreator: (params: KeyCreatorParams) => params.value.value,
        values: translateEditorEntries(enumToEditorEntries(MeasurementUnit), translateMeasurementUnit),
        comparator: enumValueEntryWithLocaleComparator
      }
    },
    {
      field: "deliveryDate",
      type: ["datepickerTypeColumn", "dateTimeTypeColumn"],
      headerValueGetter: (_: any) => $t("purchaseOrderPrediction-deliveryDate-label", { $: "Delivery Date" }),
      editable: true,
      resizable: true,
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.deliveryDate);
      },
      cellEditorParams: {
        valueRef: () => periodEndEditorRef,
        clearOnEditDisabled: true
      } as IControlledCellEditorParams
    },
    {
      field: "costCatalogueItemId",
      type: "textInputTypeColumn",
      hide: true,
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: () => $t("purchaseOrderPrediction-costCatalogueItemId-label", { $: "Cost Catalogue Item ID" })
    },
    {
      field: "costCatalogueItemString",
      type: ["costCatalogueItemPickerTypeColumn", "textFloatingFilterColumnType"],
      editable: true,
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: () => $t("purchaseOrderPrediction-costCatalogueItemString-label", { $: "Cost Catalogue Item" }),
      cellEditorParams: {
        placeholder: $t("purchaseOrderPrediction-costCatalogueItemString-label", { $: "Cost Catalogue Item" }),
        rules: [requiredRule],
        agGridFilterModel: () => ({
          providerBusinessId: {
            filterType: "text",
            type: "notBlank"
          }
        })
      },
      valueGetter: (params: ValueGetterParams<PurchaseOrderPredictionDto, CostCatalogueItemDto>) => {
        const { data } = params;
        if (!data || (!data.costCatalogueItem)) return null;

        return data.costCatalogueItem;
      },
      valueSetter: (params: ValueSetterParams<PurchaseOrderPredictionDto, CostCatalogueItemDto>) => {
        const { data, newValue } = params;
        data.costCatalogueItem = newValue ?? null;
        data.costCatalogueItemString = getCostCatalogueItemName(newValue);
        data.costCatalogueItemId = newValue?.id ?? "";
        return true;
      },
      valueFormatter: (params: ValueFormatterParams<PurchaseOrderPredictionDto>) => params.data?.costCatalogueItemString
    }
  ];
}

</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    v-model="rowData"
    identifier="purchaseOrderPredictions"
    :default-col-def="defaultColumnDef"
    edit-btn
    delete-btn
    refresh-btn
    row-selection="single"
    @prepare-columns="onPrepareColumns"
  >
  </grid-wrapper>
</template>

