import { ApiClient } from "@/services/api/common.api";
import {
  AssignOrderLinesToGroupCommand,
  CreateOrUpdateOrderLineGroupCommand,
  DeassignOrderLinesFromGroupCommand,
  GetOrderLineGroupAssignmentsPaginatedQuery,
  GetOrderLineGroupsPaginatedQuery,
  IAgGridPaginationResponse,
  OrderLineGroupAssignmentDto,
  OrderLineGroupDto,
  RegisterReleaseOrderLineGroupsToProductionCommand
} from "@masta/generated-model";

export default {
  async getAll(scenarioId: string) {
    return ApiClient.get<IAgGridPaginationResponse<OrderLineGroupDto>>(`/orderLineGroups?scenarioId=${scenarioId}`);
  },
  async getPaginated(request: GetOrderLineGroupsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<OrderLineGroupDto>>(`/orderLineGroups`, request);
  },
  async getSingle(id: string, scenarioId: string) {
    return ApiClient.get<OrderLineGroupDto>(`/orderLineGroups/${id}?scenarioId=${scenarioId}`);
  },
  async createOrUpdate(request: CreateOrUpdateOrderLineGroupCommand) {
    return ApiClient.put<string>(`/orderLineGroups`, request);
  },
  async remove(id: string, scenarioId: string) {
    return ApiClient.delete(`/orderLineGroups/${id}?scenarioId=${scenarioId}`);
  },
  async getOrderLineGroupAssignmentsPaginated(request: GetOrderLineGroupAssignmentsPaginatedQuery) {
    return ApiClient.post<IAgGridPaginationResponse<OrderLineGroupAssignmentDto>>(`/orderLineGroups/${request.orderLineGroupId}/assignments`, request);
  },
  async getOrderLineGroupAssignment(groupId: string, orderLineId: string, scenarioId: string) {
    return ApiClient.get(`/orderLineGroups/${groupId}/assignments/${orderLineId}?scenarioId=${scenarioId}`);
  },
  async assignOrderLinesToGroup(request: AssignOrderLinesToGroupCommand) {
    return ApiClient.post(`/orderLineGroups/${request.orderLineGroupId}/assign`, request);
  },
  async deassignOrderLinesFromGroup(request: DeassignOrderLinesFromGroupCommand) {
    return ApiClient.post(`/orderLineGroups/${request.orderLineGroupId}/deassign`, request);
  },
  async releaseToProduction(request: RegisterReleaseOrderLineGroupsToProductionCommand) {
    return ApiClient.post("/orderLineGroups/releaseToProduction", request);
  }
};
