<script lang="ts" setup>
import CloseCardButton from "@/components/CloseCardButton.vue";
import { translateResourceType } from "@/composables/translateEnum";
import { $t } from "@/i18n";
import { ResourceDto, ResourceInfoDto, ResourceType } from "@masta/generated-model";
import { ref } from "vue";
import { VBtn } from "vuetify/lib/components/index.mjs";

import AgreementsGrid from "@/components/Agreements/AgreementsGrid.vue";
import AssetsGrid from "@/components/Assets/AssetsGrid.vue";
import EquipmentGrid from "@/components/Equipment/EquipmentGrid.vue";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";
import LocationGrid from "@/components/Location/LocationGrid.vue";
import MaterialGrid from "@/components/Material/MaterialGrid.vue";
import PersonnelGrid from "@/components/Personnel/PersonnelGrid.vue";
import ServicesGrid from "@/components/Services/ServicesGrid.vue";
import { IRowNode, SelectionChangedEvent } from "ag-grid-community";


const modelValue = defineModel<ResourceInfoDto[] | null | undefined>("selectedResources", { required: true, default: [] });
const dialog = defineModel<boolean>("dialog", { required: true, default: false });
const props = defineProps<{
  /**
   * The resource type to be displayed in the picker. If empty, the user can select the resource type using a chip group.
   */
  resourceType?: ResourceType | null;

  /**
   * The resource types that should be displayed in the chip group. If empty, all resource types are displayed.
   */
  filteredResourceTypes?: ResourceType[];

  /**
   * The resource IDs that should be preselected in the picker.
   */
  preselectedResourceIds?: string[];
}>();

const resourceTypeChipItems = ref<{ value: ResourceType, text: string }[]>(
  [
    { value: ResourceType.Material, text: translateResourceType(ResourceType.Material) },
    { value: ResourceType.Person, text: translateResourceType(ResourceType.Person) },
    { value: ResourceType.Agreement, text: translateResourceType(ResourceType.Agreement) },
    { value: ResourceType.Equipment, text: translateResourceType(ResourceType.Equipment) },
    { value: ResourceType.Location, text: translateResourceType(ResourceType.Location) },
    { value: ResourceType.Asset, text: translateResourceType(ResourceType.Asset) },
    { value: ResourceType.Service, text: translateResourceType(ResourceType.Service) }
  ].filter(item => !props.filteredResourceTypes || props.filteredResourceTypes.includes(item.value))
);

const selectedResourceType = ref<ResourceType | null | undefined>(props.resourceType ?? (resourceTypeChipItems.value.length >= 1 ? resourceTypeChipItems.value[0].value : ResourceType.Material));

const selectedResources = ref<ResourceDto[] | null | undefined>([]);

function onBtnAdd() {
  if (selectedResources.value) {
    modelValue.value = selectedResources.value; //infly convert ResourceDto to ResourceInfoDto
  }

  dialog.value = false;
}

function onBtnCancel() {
  dialog.value = false;
}

function onSelectionChanged({ api }: SelectionChangedEvent<ResourceDto>) {
  const selectedRows = getSelectedRows(api);
  selectedResources.value = selectedRows;
}

function isRowSelectable(row: IRowNode) {
  return !!row.data && !props.preselectedResourceIds?.includes(row.data.id);
}
</script>

<template>
  <v-dialog v-model="dialog" width="100%" height="90vh" @click:outside="onBtnCancel">
    <v-card min-width="300px" height="100%" class="reference-picker-card">
      <close-card-button @click="onBtnCancel"></close-card-button>
      <v-card-title class="pa-0">
        <div class="text-h4 pb-5">{{ $t("resourcesPicker-list-addResources-label", { $: "Add resources" }) }}</div>
      </v-card-title>
      <v-card-subtitle>
        <v-chip-group v-if="props.resourceType == null && resourceTypeChipItems.length > 1" v-model="selectedResourceType" class="d-flex justify-start">
          <v-chip
            v-for="item in resourceTypeChipItems"
            :key="item.value"
            variant="elevated"
            elevation="0"
            size="large"
            slim
            density="compact"
            class="resource-type-chip"
            :color="item.value === selectedResourceType ? 'primary' : ''"
            :value="item.value">
            {{ item.text }}
          </v-chip>
        </v-chip-group>
      </v-card-subtitle>
      <v-card-text class="px-0">
        <material-grid
          v-if="selectedResourceType === ResourceType.Material || selectedResourceType === ResourceType.MaterialGroup"
          :is-row-selectable="isRowSelectable"
          @selection-changed="onSelectionChanged" />
        <personnel-grid
          v-if="selectedResourceType === ResourceType.Person || selectedResourceType === ResourceType.PersonGroup"
          :is-row-selectable="isRowSelectable"
          @selection-changed="onSelectionChanged" />
        <agreements-grid
          v-if="selectedResourceType === ResourceType.Agreement || selectedResourceType === ResourceType.AgreementGroup"
          :is-row-selectable="isRowSelectable"
          @selection-changed="onSelectionChanged" />
        <equipment-grid
          v-if="selectedResourceType === ResourceType.Equipment || selectedResourceType === ResourceType.EquipmentGroup"
          :is-row-selectable="isRowSelectable"
          @selection-changed="onSelectionChanged" />
        <location-grid
          v-if="selectedResourceType === ResourceType.Location"
          :is-row-selectable="isRowSelectable"
          @selection-changed="onSelectionChanged" />
        <assets-grid
          v-if="selectedResourceType === ResourceType.Asset"
          :is-row-selectable="isRowSelectable"
          @selection-changed="onSelectionChanged" />
        <services-grid
          v-if="selectedResourceType === ResourceType.Service"
          :is-row-selectable="isRowSelectable"
          @selection-changed="onSelectionChanged" />
      </v-card-text>
      <v-card-actions class="pa-0">
        <div class="left-col pl-0">
          <v-btn variant="elevated" color="secondary" class="w-100" @click="onBtnCancel">
            {{ $t("resourcesPicker-list-cancel-label", { $: "Cancel" }) }}
          </v-btn>
        </div>

        <div class="middle-col"></div>
        <div class="right-col pr-0">
          <v-btn variant="elevated" color="primary" class="w-100" @click="onBtnAdd">
            {{ $t("resourcesPicker-list-add-label", { $: "Add" }) }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.resource-type-chip {
  background-color: rgb(var(--v-theme-primary), 0.3);
  color: rgb(var(--v-theme-primary));
  min-height: 26px;
}
</style>
