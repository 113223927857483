<script lang="ts" setup>
import Breadcrumbs from "@/components/Layout/Breadcrumbs.vue";
import SplitLayout from "@/components/Layout/SplitLayout.vue";
import { $t } from "@/i18n";
import { computed, nextTick, onBeforeMount, onMounted, ref, watch } from "vue";
import { CreateDocumentForContextCommand, DocumentContext, ModelInstanceDto, ModelInstanceStatus, ModelSchemaDto, UpdateDocumentCommand } from "@masta/generated-model";
import { getSchemaId, useModelInstancesStore } from "@/store/ModelInstancesStore";
import { storeToRefs } from "pinia";
import { useRouteQuery } from "@vueuse/router";
import SchemaInstancesGrid from "@/components/ModelInstances/SchemaInstancesGrid.vue";
import { IRowNode } from "ag-grid-community";
import { useDebounceFn } from "@vueuse/core";
import { ModelInstanceTab } from "@/components/ModelInstances/model-instance-tab";
import { useSystemStore } from "@/store/SystemStore";
import EditModelInstance from "@/components/ModelInstances/EditModelInstance.vue";
import { useDocumentsStore } from "@/store/DocumentsStore";
import ModelInstanceResourcesGrid from "@/components/ModelInstances/ModelInstanceResourcesGrid.vue";
import ModelInstanceTasksGrid from "@/components/ModelInstances/ModelInstanceTasksGrid.vue";
import ModelInstanceOrdersGrid from "@/components/ModelInstances/ModelInstanceOrdersGrid.vue";
import ModelInstanceCostCatalogueItemsGrid from "@/components/ModelInstances/ModelInstanceCostCatalogueItemsGrid.vue";
import { ModelInstanceChange } from "@/components/ModelInstances/ModelInstanceChange";
import CloseCardButton from "@/components/CloseCardButton.vue";

const systemStore = useSystemStore();
const modelInstanceStore = useModelInstancesStore();
const documentStore = useDocumentsStore();

const { schemas, instances } = storeToRefs(modelInstanceStore);

const schemaId = useRouteQuery<string | null>("schemaId", null);
const schemaInstanceId = useRouteQuery<string | null>("schemaInstanceId", null);

const selectedSchema = ref<ModelSchemaDto | null>(null);
const selectedSchemaInstance = ref<ModelInstanceDto | null>(null);
const dialogMode = ref<"create" | "update" | null>(null);

const schemaInstanceGridRef = ref<InstanceType<typeof SchemaInstancesGrid> | null>(null);
const schemaInstanceGridApi = computed(() => schemaInstanceGridRef.value?.gridWrapperRef?.gridApi);

type SchemaInstanceTabs = "create" | "update" | "resources" | "tasks" | "orders" | "costCatalogueItems";

const currentTab = useRouteQuery<SchemaInstanceTabs | null>("tab", null);
const schemaTabs = ref<ModelInstanceTab[]>([]);

const splitLayoutRef = ref<typeof SplitLayout | null>(null);

const documentContext = computed<DocumentContext | undefined>(() => {
  let dc = documentStore.contexts.find(ctx => {
    const isIdEqual = `${ctx.schemaKey}.${ctx.profile}` === selectedSchemaInstance.value?.schemaId;
    const hasSchemaType = ctx.modelSchemaTypes.some(schemaType => selectedSchemaInstance.value?.schemaTypes.includes(schemaType));
    return isIdEqual && hasSchemaType;
  });
  if (!dc) {
    dc = documentStore.contexts.find(ctx => {
      const isIdEqual = `${ctx.schemaKey}.${ctx.profile}` === `${selectedSchema.value?.schemaKey}.${selectedSchema.value?.schemaName}`;
      const hasSchemaType = ctx.modelSchemaTypes.some(schemaType => selectedSchema.value?.modelSchemaTypes.includes(schemaType));
      return isIdEqual && hasSchemaType;
    });
  }
  return dc;
});

onBeforeMount(async () => {
  await modelInstanceStore.fetchSchemas();
  await documentStore.fetchDocumentContexts();
  loadAdditionalTabs();

  watch(schemaId, async (id: string | null) => {
    if (id) {
      selectedSchema.value = schemas.value.find((schema) => getSchemaId(schema) === id) ?? null;
    } else {
      selectedSchema.value = null;
    }
    await nextTick(() => {
      selectSchemaInstanceRow();
    });
  }, { immediate: true });

  watch(schemaInstanceId, (id: string | null) => {
    if (id) {
      selectedSchemaInstance.value = instances.value.find((instance) => instance.id === id) ?? null;
      showDetails();
    } else {
      selectedSchemaInstance.value = null;
    }
  }, { immediate: true });
});

onMounted(() => {
  watch(schemaInstanceGridApi, (api) => {
    if (api) {
      selectSchemaInstanceRow();
    }
  });
  watch(selectedSchemaInstance, (instance) => {
    if (instance) {
      dialogMode.value = "update";
    }
  });

  if (schemaInstanceId.value === null) {
    closeDetails();
  }
});

const selectSchemaInstanceRow = useDebounceFn(() => {
  schemaInstanceGridApi.value?.forEachNode((node: IRowNode<ModelInstanceDto>) => {
    if (node.data?.id === schemaInstanceId.value) {
      node.setSelected(true);
    }
  });
}, 100);

function createMainSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-model-repository");
  return gutter;
}

function createDetailsSplitPanelGutterElement(index: number, direction: "horizontal" | "vertical") {
  const gutter = document.createElement("div");
  gutter.classList.add("gutter-model-repository-details");
  return gutter;
}

function onSchemaInstanceSelected(schemaInstance: ModelInstanceDto | null) {
  schemaInstanceId.value = schemaInstance?.id ?? null;
  selectedSchemaInstance.value = schemaInstance;
  if (currentTab.value === null) {
    currentTab.value = "update";
  }
}

function loadAdditionalTabs() {
  schemaTabs.value = [];
  for (const tab of systemStore.modelInstanceTabs?.materials ?? []) {
    const schema = schemas.value.find((x) => x.schemaName === tab.schemaName && x.schemaKey === tab.schemaKey && x.version === tab.schemaVersion);
    if (schema) {
      schemaTabs.value.push({ title: tab.title ?? "", schema, contextName: tab.contextName ?? "", resourceSubTypes: tab.resourceSubTypes });
    }
  }
}

// Model instance edit
async function saveModelInstance(change: ModelInstanceChange, callback: (ok: boolean) => void) {
  let success = false;
  if (selectedSchemaInstance.value) {
    success = await modelInstanceStore.updateModelInstance({
      id: selectedSchemaInstance.value.id,
      revisionNumber: selectedSchemaInstance.value.revisionNumber,
      ...change
    } as any);
  } else {
    const id = await modelInstanceStore.createInstance({
      ...change,
      schemaName: selectedSchema.value?.schemaName,
      schemaKey: selectedSchema.value?.schemaKey,
      schemaVersion: selectedSchema.value?.version,
      release: false
    } as any);

    schemaInstanceId.value = id;
    dialogMode.value = "update";
    success = true;
  }
  if (selectedSchema.value) {
    await modelInstanceStore.fetchSchemaInstancesBySchema(selectedSchema.value);
  }
  callback(success);
  await nextTick(() => {
    // select the row in the grid
    schemaInstanceGridApi.value?.forEachNode((node: IRowNode<ModelInstanceDto>) => {
      if (node.data?.id === schemaInstanceId.value) {
        node.setSelected(true);
      }
    });
  });
}

async function saveDocument(change: ModelInstanceChange) {
  if (selectedSchemaInstance.value) {
    await documentStore.updateDocumentWithAttachment({
      businessId: change.value.businessId !== selectedSchemaInstance.value.businessId ? change.value.businessId : selectedSchemaInstance.value.businessId,
      revisionNumber: selectedSchemaInstance.value.revisionNumber,
      id: selectedSchemaInstance.value.id,
      tags: [...change.tags],
      ...change.value.attachment
    } as UpdateDocumentCommand);
  } else {
    const id = await documentStore.createDocumentWithAttachment({
      contextName: documentContext.value?.contextName,
      businessId: change.businessId,
      tags: [...change.tags],
      ...change.value.attachment
    } as CreateDocumentForContextCommand);
  }
}

async function editCancelled() {
  if (dialogMode.value === "create") {
    schemaInstanceId.value = null;
    selectedSchemaInstance.value = null;
    dialogMode.value = null;
    currentTab.value = null;
    console.log("editCancelled");
  }
}

async function onCreateNewInstance() {
  schemaInstanceGridApi.value?.deselectAll();
  schemaInstanceId.value = null;
  selectedSchemaInstance.value = null;
  dialogMode.value = "create";
  currentTab.value = "create";
  console.log("onCreateNewInstance");
}

async function onReleaseInstance(instance: ModelInstanceDto) {
  if (instance.status === ModelInstanceStatus.WorkInProgress) {
    await modelInstanceStore.release(instance);
  }
}

async function onArchiveInstance(instance: ModelInstanceDto) {
  if (instance.status !== ModelInstanceStatus.Archived) {
    await modelInstanceStore.archive(instance);
  }
}

async function onInstanceNewRevision(instance: ModelInstanceDto) {
  await modelInstanceStore.createNewRevision(instance);
}

async function onCopyInstance(instance: ModelInstanceDto) {
  await modelInstanceStore.makeCopy(instance);
}

async function onDeleteInstance(instance: ModelInstanceDto) {
  schemaInstanceId.value = null;
  selectedSchemaInstance.value = null;
}

function showDetails() {
  splitLayoutRef.value?.showDetails();
}

function closeDetails() {
  splitLayoutRef.value?.closeDetails();
}
</script>

<template>
  <split-layout ref="splitLayoutRef" identifier="model-repository" :gutter="createMainSplitPanelGutterElement">
    <template #master>
      <v-card elevation="0" class="d-flex flex-column fill-height">
        <v-card-title>
          <breadcrumbs>
            {{ $t("modelRepository-view-title", { $: "Model repository" }) }}
            <v-chip
              v-if="selectedSchema"
              class="pl-2 resource-chip"
              variant="elevated"
              size="small"
              elevation="0"
              density="compact">
              {{ selectedSchema.schemaName }}
            </v-chip>
          </breadcrumbs>
        </v-card-title>
        <v-card-text v-if="selectedSchema" class="fill-height">
          <schema-instances-grid
            ref="schemaInstanceGridRef"
            :schema="selectedSchema"
            @create="onCreateNewInstance"
            @release="onReleaseInstance"
            @archive="onArchiveInstance"
            @new-revision="onInstanceNewRevision"
            @copy="onCopyInstance"
            @delete="onDeleteInstance"
            @instance-selected="onSchemaInstanceSelected"
          />
        </v-card-text>
        <v-card-text v-else class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("modelRepository-view-noSchemaSelected-label", { $: "No model schema selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <template #detail>
      <v-card v-if="dialogMode === 'create' || dialogMode === 'update'" elevation="7" class="d-flex flex-column fill-height model-instance-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-title>
          <breadcrumbs>{{ selectedSchema?.schemaName }}</breadcrumbs>
        </v-card-title>
        <v-card-subtitle style="opacity: unset;">
          <v-tabs
            v-model="currentTab"
            class="tab-details text-disabled bg-white"
            selected-class="v-slide-group-item--active text-primary bg-white"
            density="compact"
            color="primary"
            show-arrows
          >
            <v-tab :value="dialogMode">
              <v-icon class="pr-2">{{ dialogMode === "create" ? "mdi-plus" : "mdi-pencil" }}</v-icon>
              <span v-if="dialogMode === 'create'">{{ $t("modelInstance-view-createModelInstance-label", { $: "Create Model Definition" }) }}</span>
              <span v-if="dialogMode === 'update'">{{ $t("modelInstance-view-updateModelInstance-label", { $: "Model Definition" }) }}</span>
            </v-tab>
            <v-tab value="resources">
              <v-icon class="pr-2">mdi-tag-multiple</v-icon>
              {{ $t("modelInstanceAssignments-view-resources-tab-label", { $: "Resource Assign." }) }}
            </v-tab>
            <v-tab value="tasks">
              <v-icon class="pr-2">mdi-list-box-outline</v-icon>
              {{ $t("modelInstanceAssignments-view-tasks-tab-label", { $: "Tasks Assign." }) }}
            </v-tab>
            <v-tab value="orders">
              <v-icon class="pr-2">mdi-cash-register</v-icon>
              {{ $t("modelInstanceAssignments-view-orders-tab-label", { $: "Orders Assign." }) }}
            </v-tab>
            <v-tab value="costCatalogueItems">
              <v-icon class="pr-2">mdi-currency-usd</v-icon>
              {{ $t("modelInstanceAssignments-view-costCatalogueItems-tab-label", { $: "Cost Catalogue Items Assign." }) }}
            </v-tab>
          </v-tabs>
        </v-card-subtitle>
        <v-card-text class="fill-height">
          <v-window v-model="currentTab" class="bg-white flex-fill d-block" style="width: 100%; height: 100%">
            <v-window-item v-if="selectedSchema" value="create" class="fill-height pt-5">
              <edit-model-instance
                :context-name="documentContext?.contextName ?? ''"
                :schema="selectedSchema"
                :model-instance="selectedSchemaInstance"
                @save="saveModelInstance"
                @save-document="saveDocument"
                @cancel="editCancelled"
              />
            </v-window-item>
            <v-window-item v-if="selectedSchemaInstance" value="update" class="fill-height pt-5">
              <edit-model-instance
                :context-name="documentContext?.contextName ?? ''"
                :schema="selectedSchema"
                :model-instance="selectedSchemaInstance"
                @save="saveModelInstance"
                @save-document="saveDocument"
                @cancel="editCancelled"
              />
            </v-window-item>
            <v-window-item value="resources" class="fill-height pt-5">
              <model-instance-resources-grid v-if="selectedSchemaInstance" :model-value="selectedSchemaInstance.id" />
            </v-window-item>
            <v-window-item value="tasks" class="fill-height pt-5">
              <model-instance-tasks-grid v-if="selectedSchemaInstance" :model-value="selectedSchemaInstance.id" />
            </v-window-item>
            <v-window-item value="orders" class="fill-height pt-5">
              <model-instance-orders-grid v-if="selectedSchemaInstance" :model-value="selectedSchemaInstance.id" />
            </v-window-item>
            <v-window-item value="costCatalogueItems" class="fill-height pt-5">
              <model-instance-cost-catalogue-items-grid v-if="selectedSchemaInstance" :model-value="selectedSchemaInstance.id" />
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
      <v-card v-else elevation="7" class="d-flex flex-column fill-height no-model-schema-card">
        <close-card-button @click="closeDetails"></close-card-button>
        <v-card-text class="fill-height">
          <v-row class="fill-height" no-gutters>
            <v-col cols="12" class="d-flex align-center justify-center">
              <label class="text-secondary text-h6 font-weight-regular">
                {{ $t("modelRepository-view-noSchemaInstanceSelected-label", { $: "No model schema instance selected" }) }}
              </label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </split-layout>
</template>

<style scoped lang="scss">
.schema-chip {
  background-color: rgb(var(--v-theme-primary), 0.3);
  color: rgb(var(--v-theme-primary));
  min-height: 22px;
}

.model-instance-card {
  //border: 2px solid rgb(var(--v-theme-primary));
  //border-radius: 0;

  .tabs {
    .v-tab {
      color: rgba(var(--v-theme-on-background), var(--v-disabled-opacity));
    }

    .v-tab:not(.v-tab--selected) {
      border-bottom: 1px solid #e0e0e0;
    }
  }

  .close-card-button {
    position: absolute;
    top: 4px;
    right: 10px;
    z-index: 9999;
  }
}

.no-model-schema-card {
  //border: 2px solid rgb(var(--v-theme-primary), 0.0);
  //border-radius: 0;

  .close-card-button {
    position: absolute;
    top: 4px;
    right: 10px;
  }
}

.tab-details {
  position: relative;

  > .v-tab {
    border-radius: 0.5rem 0.5rem 0 0;
  }
}

:deep(.gutter-model-repository-details) {
  background-color: rgba(var(--v-theme-primary), .5);
  height: 2px !important;
  z-index: 1;
}

.details {
  :deep(.panel-1), :deep(.panel-2) {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

</style>
