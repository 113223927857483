<script lang="ts" setup>
import { getSchemaId, useModelInstancesStore } from "@/store/ModelInstancesStore";
import { storeToRefs } from "pinia";
import { computed, onBeforeMount, ref, watch } from "vue";
import { useRouteQuery } from "@vueuse/router";
import { ModelSchemaDto, ResourceInfoDto } from "@masta/generated-model";
import { useRouter } from "vue-router";
import { $t } from "@/i18n";
import { useScenariosStore } from "@/store/ScenariosStore";
import { nameOrBusinessIdOrIdOrNull } from "@/components/ValueCellEditor/CommonFormatters";
import { useStorage } from "@vueuse/core";
import { $authService } from "@/services/AuthService";

const searchText = defineModel<string | null | undefined>({ required: true });

const emits = defineEmits(["close"]);

const store = useModelInstancesStore();

const { schemas } = storeToRefs(store);

const recentModels = useStorage<ModelSchemaDto[]>(`recent-models-${$authService.tenantId}`, null, undefined, {
  listenToStorageChanges: true,
  serializer: {
    read: (v) => (v ? JSON.parse(v) : null),
    write: (v) => JSON.stringify(v)
  }
});

const filteredSchemas = computed(() => {
  if (searchText.value === null || searchText.value === undefined || searchText.value === "") {
    return [];
  }
  return schemas.value.filter((schema) => {
    return schema.schemaName.toLowerCase().includes(searchText.value?.toLowerCase() ?? "");
  });
});

const schemaId = useRouteQuery<string | null>("schemaId", null);

const selectedSchema = ref<ModelSchemaDto | null>(null);

onBeforeMount(async () => {
  await store.fetchSchemas();
  watch(schemaId, (id: string | null) => {
    if (id) {
      selectedSchema.value = schemas.value.find((schema) => getSchemaId(schema) === id) ?? null;
    } else {
      selectedSchema.value = null;
    }
  }, { immediate: true });
});

const router = useRouter();

function onSchemaClick(schema: ModelSchemaDto) {
  addRecentModel(schema);
  router.push({ name: "ModelRepository", query: { schemaId: getSchemaId(schema) }, params: { scenarioId: useScenariosStore().scenarioBusinessId } });
  emits("close");
}

/**
 * Add selected model to recent models list. Reorder the list to keep the most 10 recent models at the top.
 */
function addRecentModel(model: ModelSchemaDto | null | undefined) {
  if (model == null) {
    return;
  }

  const recentModelsValue = recentModels.value ?? [];
  const index = recentModelsValue.findIndex((m) => getSchemaId(m) === getSchemaId(model));
  if (index !== -1) {
    recentModelsValue.splice(index, 1);
  }
  recentModelsValue.unshift(model);
  recentModels.value = recentModelsValue.slice(0, 10);
}
</script>

<template>
  <v-row no-gutters class="fill-height" data-cy="model-schema-search-list">
    <v-col cols="6" class="fill-height overflow-y-auto">
      <div class="">
        <v-list class="list pa-0">
          <v-fade-transition group>
            <v-list-item
              v-for="schema in filteredSchemas"
              :key="getSchemaId(schema)"
              :active="!!selectedSchema && (getSchemaId(selectedSchema) === getSchemaId(schema))"
              :title="schema.schemaName"
              :subtitle="schema.schemaName"
              :base-color="!!selectedSchema && (getSchemaId(selectedSchema) === getSchemaId(schema)) ? 'primary': undefined"
              cols="12"
              class="pa-0 rounded"
              density="compact"
              data-cy="schema"
              @click="onSchemaClick(schema)"
            />
            <v-list-item
              v-if="filteredSchemas.length === 0"
              key="empty"
              :title="$t('topSearch-modelRepositorySearchRequired-caption', { $: 'Please type something to search models...' })"
              cols="12"
              class="rounded text-center"
              density="compact"
            />
          </v-fade-transition>
        </v-list>
      </div>
    </v-col>
    <v-divider vertical class="fill-height"></v-divider>
    <v-col cols="6">
      <v-card variant="text">
        <v-card-title>
          {{ $t("topSearch-lastSelectedModelSchema-label", { $: "Last selected models" }) }}
        </v-card-title>
        <v-card-text class="d-flex align-center flex-wrap">
          <v-chip
            v-for="schema in recentModels"
            :key="getSchemaId(schema)"
            class="last-used-model-chip mb-2 mr-2"
            variant="elevated"
            size="small"
            elevation="0"
            density="compact"
            @click="onSchemaClick(schema)">
            {{ schema.schemaName }}
          </v-chip>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">
.list {
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.last-used-model-chip {
  background-color: rgb(var(--v-theme-primary), 0.3);
  color: rgb(var(--v-theme-primary));
  min-height: 26px;
}
</style>
