<script setup lang="ts">
import { $t } from "@/i18n";
import GridWrapper from "@/components/Grid/GridWrapper.vue";
import { AssignOrderLinesToGroupCommand, CustomerOrderLineDetailsDto, DocumentDto, OrderLineGroupAssignmentDto, OrderLineGroupDto } from "@masta/generated-model";
import { inject, reactive, ref, watch } from "vue";
import { OrderLineGroupAssignmentServerSideDataSource } from "@/components/OrderLineGroups/OrderLineGroupAssignmentServerSideDataSource";
import { GridWrapperComponent } from "@/components/Grid/GridWrapperComponent";
import { $dateTimeFormatterSymbol, DateFormatter } from "@masta/shared";
import { translateMeasurementUnit, translateOrderLineStatus } from "@/composables/translateEnum";
import { getOrderLineStatusCellStyle } from "@/components/CustomerOrders/CustomerOrderUtils";
import OrderLingGroupAssignmentDialog from "@/components/OrderLineGroups/OrderLingGroupAssignmentDialog.vue";
import ApiService from "@/services/api";
import { GetRowIdParams, SelectionChangedEvent } from "ag-grid-community";
import { getSelectedRows } from "@/components/Grid/UseGridSelection";

const props = defineProps<{ selectedGroup: OrderLineGroupDto }>();

const serverSideDataSource = reactive(new OrderLineGroupAssignmentServerSideDataSource("order-line-group-assignments"));
serverSideDataSource.useFilteringByOrderLineGroupId(props.selectedGroup.id);
const gridWrapperRef = ref<GridWrapperComponent>();
const $dateTimeFormatter = inject<DateFormatter>($dateTimeFormatterSymbol)!;
const assignmentDialog = ref(false);
const selectedRows = ref<OrderLineGroupAssignmentDto[]>([]);

watch(props, (newProps) => {
  serverSideDataSource.useFilteringByOrderLineGroupId(newProps.selectedGroup.id);
  gridWrapperRef.value?.gridApi.refreshServerSide();
});

function getRowId(params: GetRowIdParams<OrderLineGroupAssignmentDto>) {
  return `${params.data.orderLineGroupId}-${params.data.orderLineId}`;
}

function onAssign() {
  assignmentDialog.value = true;
}

async function deassign() {
  if (selectedRows.value.length === 0) {
    return;
  }
  await ApiService.orderLineGroups.deassignOrderLinesFromGroup({
    orderLineGroupId: props.selectedGroup.id,
    orderIds: selectedRows.value.map((assignment) => assignment.orderId),
    orderLineIds: selectedRows.value.map((assignment) => assignment.orderLineId),
    scenarioId: props.selectedGroup.scenarioId
  });
  gridWrapperRef.value?.gridApi?.refreshServerSide();
}

async function assign(lines: CustomerOrderLineDetailsDto[]) {
  assignmentDialog.value = false;
  await ApiService.orderLineGroups.assignOrderLinesToGroup({
    orderLineGroupId: props.selectedGroup.id,
    orderIds: lines.map((line) => line.orderId),
    orderLineIds: lines.map((line) => line.id),
    scenarioId: props.selectedGroup.scenarioId
  });
  gridWrapperRef.value?.gridApi?.refreshServerSide();
}

function onSelectionChanged(event: SelectionChangedEvent) {
  const { api } = event;
  selectedRows.value = getSelectedRows(api);
}

function onPrepareColumns(columnDefs: any) {
  columnDefs.value = [
    {
      field: "itemName",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("orderLineGroupAssignment-list-itemName-label", { $: "Item Name" })
    },
    {
      field: "itemBusinessId",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      headerValueGetter: (_: any) => $t("orderLineGroupAssignment-list-itemBusinessId-label", { $: "Item Business Id" })
    },
    {
      field: "description",
      editable: false,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      headerValueGetter: (_: any) => $t("orderLineGroupAssignment-list-description-label", { $: "Description" })
    },
    {
      field: "quantity",
      sortable: true,
      editable: false,
      filter: "agNumberColumnFilter",
      resizable: true,
      headerValueGetter: (_: any) => $t("orderLineGroupAssignment-list-quantity-label", { $: "Quantity" })
    },
    {
      field: "measurementUnit",
      editable: false,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      type: "enumTypeColumn",
      valueFormatter: (params: any) => (params.data ? translateMeasurementUnit(params.data.measurementUnit) : null),
      headerValueGetter: (_: any) => $t("orderLineGroupAssignment-list-quantityUnit-label", { $: "Measurement Unit" })
    },
    {
      field: "position",
      editable: false,
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      headerValueGetter: (_: any) => $t("orderLineGroupAssignment-list-position-label", { $: "Position" })
    },
    {
      field: "deliveryDate",
      editable: false,
      sortable: true,
      filter: "agDateColumnFilter",
      resizable: true,
      valueFormatter: (params: any) => {
        return $dateTimeFormatter(params.data.deliveryDate);
      },
      type: "datepickerTypeColumn",
      headerValueGetter: (_: any) => $t("orderLineGroupAssignment-list-deliveryDate-label", { $: "Delivery Date" })
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      sortable: true,
      filter: false,
      resizable: true,
      cellStyle: (params: any) => getOrderLineStatusCellStyle(params.data.status),
      valueGetter: (params: any) => translateOrderLineStatus(params.data.status),
      headerValueGetter: (_: any) => $t("orderLineGroupAssignment-list-status-label", { $: "Status" })
    },
    {
      field: "orderBusinessId",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("orderLineGroupAssignment-list-orderBusinessId-label", { $: "Order Business Id" })
    },
    {
      field: "customerName",
      editable: false,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: (_: any) => $t("orderLineGroupAssignment-list-customerName-label", { $: "Customer" })
    }
  ];
}
</script>

<template>
  <grid-wrapper
    ref="gridWrapperRef"
    identifier="order-line-group-assignments"
    row-selection="multiple"
    :server-side="true"
    :server-side-datasource="serverSideDataSource"
    :server-side-infinite-scroll="false"
    :pagination="false"
    :get-row-id="getRowId"
    refresh-btn
    @prepare-columns="onPrepareColumns"
    @selection-changed="onSelectionChanged"
  >
    <template #custom-buttons>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <v-btn size="small" v-bind="props" variant="text" density="compact" class="mr-4" @click="onAssign">
            <v-icon class="pr-4" icon="mdi-playlist-plus" />
            {{ $t("orderLineGroupAssignment-list-assign-action", { $: "Assign" }) }}
          </v-btn>
        </template>
        <span>{{ $t("OrderLineGroupAssignment-list-assign-action-tooltip", { $: "Assign" }) }}</span>
      </v-tooltip>
      <v-tooltip bottom open-delay="300">
        <template #activator="{ props }">
          <v-btn size="small" v-bind="props" variant="text" density="compact" class="mr-4" :disabled="selectedRows.length === 0" @click="deassign">
            <v-icon class="pr-4" icon="mdi-playlist-minus" />
            {{ $t("orderLineGroupAssignment-list-deassign-action", { $: "Deassign" }) }}
          </v-btn>
        </template>
        <span>{{ $t("OrderLineGroupAssignment-list-deassign-action-tooltip", { $: "Deassign" }) }}</span>
      </v-tooltip>
    </template>
  </grid-wrapper>
  <order-ling-group-assignment-dialog :opened="assignmentDialog" @confirm="assign" @cancel="assignmentDialog = false" />
</template>

<style scoped lang="scss"></style>
